<template>
    <span v-if="subscriptions && subscriptions.length > 0">
        <v-card
            color="#6772E5"
            theme="dark"
            v-for="subscription of subscriptions"
            :key="subscription.subscriptionId"
            class="vesselCard"
            height="200px"
            min-width="220px"
        >
            <v-card-title>SUBSCRIPTION</v-card-title>
            <v-card-subtitle>{{ subscription.title }}</v-card-subtitle>
            <v-card-subtitle
                >{{ format(subscription.currentPeriodStartDate, 'M/d/yy') }} - {{ format(subscription.currentPeriodEndDate, 'M/d/yy') }}</v-card-subtitle
            >

            <v-card-text>
                <div>
                    <subscription-status-chip :subscription="subscription" />
                </div>
                <div class="mt-2" v-if="addPaymentMethodBanner !== undefined">
                    <v-chip variant="flat" color="orange"><v-icon start icon="mdi-credit-card-outline" /> NO PAYMENT METHOD</v-chip>
                </div>
            </v-card-text>

            <v-menu>
                <template v-slot:activator="{ props }">
                    <v-btn
                        icon="mdi-dots-vertical-circle"
                        class="vesselCardAction"
                        title="Location Actions"
                        variant="text"
                        density="compact"
                        size="x-large"
                        v-bind="props"
                    ></v-btn>
                </template>
                <v-list>
                    <v-list-item>
                        <v-list-item button title="View on Stripe" :href="subscription.stripeSubscriptionUrl" target="_stripe">
                            <template v-slot:prepend>
                                <img src="../../assets/stripe-icon.svg" class="stripeIcon" />
                            </template>
                        </v-list-item>
                        <v-list-item button title="Copy Payment Method Update URL" @click="copyPaymentMethodUpdateUrl">
                            <template v-slot:prepend>
                                <v-icon icon="mdi-content-copy" class="mr-2"></v-icon>
                            </template>
                        </v-list-item>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-card>
    </span>

    <v-card v-else color="white" theme="dark" class="vesselCard noSubscriptionCard" height="200px">
        <v-card-title>NO SUBSCRIPTION</v-card-title>
        <v-card-text>
            <v-btn v-if="subscriptionUpgrades && subscriptionUpgrades.length > 0" icon v-bind="props" size="small" color="#6772E5" @click="showAddSubscription">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <p v-else>No Upgrades</p>
        </v-card-text>
    </v-card>

    <v-dialog v-model="showAddSubscriptionDialog" :max-width="500">
        <v-card :loading="loadingAddSubscription">
            <v-card-title>Add Subscription</v-card-title>
            <v-card-text v-if="loadingAddSubscription" class="loadingAddSubscription">
                <v-progress-circular indeterminate size="32"></v-progress-circular>
            </v-card-text>
            <v-card-text v-else>
                <div v-if="hasIncludedSubscription" class="useIncludedSubscription">
                    <h3>Use Included Subscription</h3>
                    <div class="indented">
                        <p>This vessel's monitor has an available included subscription.</p>
                        <v-btn color="success" size="small" :loading="loadingUseIncludedSubscription" @click="useIncludedSubscription"
                            >Use Included Subscription</v-btn
                        >
                    </div>
                </div>

                <h3>Purchase a Subscription</h3>
                <v-list>
                    <v-list-item
                        v-for="subscriptionUpgrade in subscriptionUpgrades"
                        :key="subscriptionUpgrade.priceLookupKey"
                        :title="subscriptionUpgrade.title"
                        :subtitle="subscriptionUpgrade.uiComponents.price.priceText"
                    >
                        <template v-slot:append>
                            <v-list-item-action end>
                                <v-btn
                                    color="primary"
                                    size="small"
                                    :loading="loadingSubscriptionCheckout"
                                    @click="purchaseASubscription(subscriptionUpgrade.priceLookupKey)"
                                    >Choose</v-btn
                                >
                            </v-list-item-action>
                        </template>
                    </v-list-item>
                </v-list>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="showAddSubscriptionDialog = false">Cancel</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
import { type PropType, ref, computed } from 'vue'
import {
    MonitorSubscriptionGrantStatusesV1,
    type AddPaymentMethodBannerV1,
    type SubscriptionUpgradeV1,
    type SubscriptionV1
} from '@general-galactic/crystal-api-client'
import { copyToClipboard, handleApiError } from '@/lib/utils'
import { useApi } from '@/api'
import { format } from 'date-fns'
import SubscriptionStatusChip from '../subscriptions/SubscriptionStatusChip.vue'
import { useGeneralStore } from '@/stores/general'

const props = defineProps({
    accountId: {
        type: Number,
        required: true
    },
    vesselId: {
        type: Number,
        required: true
    },
    monitorId: {
        type: String,
        required: false
    },
    subscriptions: {
        type: Array as PropType<SubscriptionV1[]>,
        required: false
    },
    subscriptionUpgrades: {
        type: Array as PropType<SubscriptionUpgradeV1[]>,
        required: false
    },
    addPaymentMethodBanner: {
        type: Object as PropType<AddPaymentMethodBannerV1>,
        required: false
    }
})

const emits = defineEmits(['addedSubscription'])

const loadingIncludedSubscription = ref(false)

const loadingSubscriptionCheckout = ref(false)
const showAddSubscriptionDialog = ref(false)

const hasIncludedSubscription = ref(false)
const loadingUseIncludedSubscription = ref(false)

const loadingAddSubscription = computed(() => {
    return loadingIncludedSubscription.value || loadingSubscriptionCheckout.value
})

const showAddSubscription = () => {
    checkForIncludedSubscription()
    showAddSubscriptionDialog.value = true
}

const useIncludedSubscription = async () => {
    handleApiError(
        async () => {
            loadingUseIncludedSubscription.value = true

            const result = await useApi().putIncludedSubscriptionV1({
                accountId: props.accountId,
                vesselId: props.vesselId
            })

            if (result.success) {
                console.log('Added included subscription', result)
                showAddSubscriptionDialog.value = false
                emits('addedSubscription')
            } else {
                const generalStore = useGeneralStore()
                generalStore.$patch({
                    globalErrorMessage: 'Unable to use included subscription. Please confirm that the customer is not deleted in Stripe.'
                })
            }
        },
        async () => {
            loadingUseIncludedSubscription.value = false
        }
    )
}

const checkForIncludedSubscription = async () => {
    const monitorId = props.monitorId
    if (monitorId === undefined) return

    handleApiError(
        async () => {
            loadingIncludedSubscription.value = true

            const monitorDetails = await useApi().getMonitorDetailsV1({
                monitorId
            })
            hasIncludedSubscription.value = monitorDetails.subscriptionGrantStatus.status === MonitorSubscriptionGrantStatusesV1.Available
        },
        async () => {
            loadingIncludedSubscription.value = false
        }
    )
}

const purchaseASubscription = async (priceLookupKey: string) => {
    await handleApiError(
        async () => {
            loadingSubscriptionCheckout.value = true

            const result = await useApi().postSubscriptionPaymentSessionV1({
                accountId: props.accountId,
                vesselId: props.vesselId,
                stripePriceLookupKey: priceLookupKey,
                postSubscriptionPaymentSessionBodyV1: {}
            })

            const w = window.open(result.stripeCheckoutSessionUrl, '_stripe')
            if (w !== null) {
                const timer = setInterval(() => {
                    if (w.closed) {
                        clearInterval(timer)
                        clearTimeout(maxTimeout)
                        emits('addedSubscription')
                    }
                }, 500)
                const maxTimeout = setTimeout(() => {
                    clearInterval(timer)
                }, 10 * 60 * 1000)
            }
        },
        async () => {
            loadingSubscriptionCheckout.value = false
        }
    )
}

const copyPaymentMethodUpdateUrl = async () => {
    handleApiError(async () => {
        const result = await useApi().postStripeUpdatePaymentMethodPortalSessionV1({
            accountId: props.accountId,
            postStripePaymentMethodUpdatePortalSessionV1Body: {}
        })
        copyToClipboard(result.stripePaymentMethodUpdatePortalUrl)
    })
}
</script>

<style lang="scss">
.noSubscriptionCard {
    text-align: center;
    border: 3px dashed #6772e5;
    color: black;

    .v-btn {
        color: white;
    }
}

.stripeIcon {
    height: 14px;
    margin-right: 8px;
}

.useIncludedSubscription {
    margin-bottom: 30px;

    .indented {
        padding-left: 16px;
    }

    .v-btn {
        margin-top: 10px;
    }
}

.loadingAddSubscription {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
