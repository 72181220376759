<script lang="ts" setup>
import { useApi } from '@/api'
import type { VesselChemCheckResultV1 } from '@general-galactic/crystal-api-client'
import { useBreadCrumbsStore } from '@/stores/breadCrumbs'
import { useVesselStore } from '@/stores/vessel'
import { computed, onMounted, ref } from 'vue'
import { formatDateForVesselTimezone, handleApiError } from '@/lib/utils'
import SmallReadingCell from '@/components/SmallReadingCell.vue'
import { toReadingsArray } from '@/lib/readings'

const breadCrumbsStore = useBreadCrumbsStore()
const vesselStore = useVesselStore()

const loading = ref(false)

const props = defineProps({
    accountId: {
        type: Number,
        required: true
    },
    vesselId: {
        type: Number,
        required: true
    }
})

onMounted(async () => {
    await vesselStore.loadVessel(props.accountId, props.vesselId)
    getChemCheckHistory()
    breadCrumbsStore.$patch({
        items: [
            {
                text: `Account #${props.accountId}`,
                to: {
                    name: 'AccountDetails',
                    params: {
                        accountId: props.accountId
                    }
                }
            },
            {
                text: vesselStore.vessel?.name,
                to: {
                    name: 'VesselDetails',
                    params: {
                        accountId: props.accountId,
                        vesselId: props.vesselId
                    }
                }
            },
            {
                text: 'Chem-Check History'
            }
        ]
    })
})

const vessel = computed(() => {
    return vesselStore.vessel
})

const results = ref<VesselChemCheckResultV1[] | undefined>()

async function getChemCheckHistory() {
    handleApiError(
        async () => {
            loading.value = true
            const result = await useApi().getVesselChemCheckHistoryV1({ accountId: props.accountId, vesselId: props.vesselId })
            results.value = result.recentChemCheckResults
        },
        async () => {
            loading.value = false
        }
    )
}
</script>

<template>
    <v-container fluid>
        <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
        <v-table v-else>
            <thead>
                <tr>
                    <th></th>
                    <th>Date</th>
                    <th>Readings</th>
                    <th>Status</th>
                    <th>Actions</th>
                    <th>Notified</th>
                    <th>Error</th>
                    <th>Source</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="result in results" :key="String(result.chemcheckDate)">
                    <td>
                        <v-icon v-if="result.trigger === 'timed'" icon="mdi-clock-time-three" color="orange" title="Timed" />
                        <v-icon v-else-if="result.trigger === 'checkin'" icon="mdi-cloud-check" color="blue" title="Monitor Checked In" />
                        <span v-else-if="result.trigger !== undefined">{{ result.trigger }}</span>
                    </td>
                    <td>{{ formatDateForVesselTimezone({ date: result.chemcheckDate, vesselTimezoneId: vessel?.location?.timeZone?.timeZoneId }) }}</td>
                    <td>
                        <small-reading-cell
                            v-for="reading of toReadingsArray(result.readings)"
                            :key="reading.title"
                            :reading-type="reading.abbreviation"
                            :accountId="accountId"
                            :vesselId="vesselId"
                            :title="reading.title"
                            :reading="reading"
                            :show-raw="true"
                            :vessel-timezone-id="vessel?.location?.timeZone?.timeZoneId"
                            :show-status-hold="true"
                        />
                    </td>
                    <td>{{ result.waterStatus }}</td>
                    <td>
                        <v-menu open-on-hover>
                            <template v-slot:activator="{ props }">
                                <v-chip v-bind="props" color="blue"
                                    ><strong>{{ result.actionCount }}</strong></v-chip
                                >
                            </template>
                            <v-card>
                                <v-card-text>
                                    <div v-if="result.actionCount === 0">No Actions</div>
                                    <div v-else-if="result.actionSummaries?.summaries">
                                        <table>
                                            <tr v-for="summary of result.actionSummaries?.summaries" :key="summary.type">
                                                <td>
                                                    <v-icon class="notifyIcon" color="orange" icon="mdi-chat-alert"></v-icon><strong>{{ summary.type }}</strong>
                                                </td>
                                                <td>{{ summary.adjustmemnt }}</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div v-else>No Info</div>
                                </v-card-text>
                            </v-card>
                        </v-menu>
                    </td>
                    <td>
                        <v-menu open-on-hover v-if="result.notifiedUser">
                            <template v-slot:activator="{ props }">
                                <v-icon v-bind="props" color="orange" icon="mdi-chat-alert"></v-icon>
                            </template>
                            <v-card>
                                <v-card-text>
                                    <div v-if="result.notificationText">{{ result.notificationText }}</div>
                                    <div v-else>too old to view text</div>
                                </v-card-text>
                            </v-card>
                        </v-menu>
                    </td>
                    <td>
                        <v-menu open-on-hover v-if="result.error">
                            <template v-slot:activator="{ props }">
                                <v-icon v-bind="props" color="red" icon="mdi-alert-circle"></v-icon>
                            </template>
                            <v-card :text="result.error" />
                        </v-menu>
                    </td>
                    <td>
                        {{ result.updateSource }}
                    </td>
                    <td>
                        <v-menu open-on-hover v-if="result.checkin">
                            <template v-slot:activator="{ props }">
                                <v-icon v-bind="props" color="blue" icon="mdi-eye" class="mr-3"></v-icon>
                            </template>
                            <v-card>
                                <v-card-text>
                                    <h3>CHECKIN</h3>
                                    <pre>{{ result.checkin }}</pre>
                                </v-card-text>
                            </v-card>
                        </v-menu>
                        <v-btn
                            v-if="result.awsRequestId"
                            size="small"
                            :to="{
                                name: 'VesselStatusUpdateLambdaLogs',
                                params: { accountId, vesselId, requestId: result.awsRequestId, eventDate: result.chemcheckDate.toISOString() }
                            }"
                        >
                            <template v-slot:prepend>
                                <v-icon icon="mdi-aws" class="mr-2" color="orange"></v-icon>
                            </template>
                            View Logs
                        </v-btn>
                    </td>
                </tr>
            </tbody>
        </v-table>
    </v-container>
</template>

<style lang="scss" scoped>
.notifyIcon {
    margin-right: 4px;
}
</style>
