<template>
    <div>
        <v-text-field
            autofocus
            v-model="searchTerm"
            prepend-inner-icon="mdi-magnify"
            :loading="loadingSearchResults"
            label="Search"
            placeholder="Monitor ID"
            clearable
        ></v-text-field>
        <div v-if="loadingSearchResults" class="m-4">
            <v-progress-circular indeterminate size="24"></v-progress-circular>
        </div>
        <v-list
            v-else-if="canUseSearchTerm && searchResults && searchResults.length > 0"
            v-for="searchResult of searchResults"
            :key="searchResult.monitorId"
            @click="emit('picked:monitor', searchResult)"
            lines="one"
            density="compact"
        >
            <v-list-item :title="searchResult.monitorId" :subtitle="subTitle(searchResult)" color="primary" class="monitorResult"></v-list-item>
        </v-list>
        <div v-else-if="canUseSearchTerm">No Results for '{{ searchTerm }}'</div>
    </div>
</template>

<script lang="ts" setup>
import { useApi } from '@/api'
import type { MonitorsSearchResultV1 } from '@general-galactic/crystal-api-client'
import { debounce } from '@general-galactic/crystal-webapp-utils'
import { computed, ref, watch } from 'vue'

const loadingSearchResults = ref(false)
const searchTerm = ref<string | undefined>()
const searchResults = ref<MonitorsSearchResultV1[] | undefined>()

const emit = defineEmits<{
    (event: 'picked:monitor', monitor: MonitorsSearchResultV1): void
}>()

const canUseSearchTerm = computed(() => {
    if (!searchTerm.value) return false
    return searchTerm.value.length > 2
})

watch(
    searchTerm,
    debounce(() => {
        if (!canUseSearchTerm.value) {
            searchResults.value = undefined
            return
        }
        if (searchTerm.value) search(searchTerm.value)
    }, 300)
)

const search = async (searchTerm: string) => {
    if (!canUseSearchTerm.value) return
    try {
        loadingSearchResults.value = true
        const result = await useApi().searchMonitorsV1({ monitorsSearchInputV1: { searchTerm } })
        searchResults.value = result.results
    } finally {
        loadingSearchResults.value = false
    }
}

const subTitle = (monitor: MonitorsSearchResultV1): string => {
    const parts: string[] = []
    if (monitor.owningAccountId) parts.push(`owning account: #${monitor.owningAccountId}`)
    if (monitor.vesselName) parts.push(`attached to: ${monitor.vesselName}`)
    return parts.join(' - ')
}
</script>

<style lang="scss" scoped>
.monitorResult {
    cursor: pointer;
}
</style>
