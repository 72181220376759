<template>
    <div>
        <div>
            <v-row>
                <v-col>
                    <v-card>
                        <v-card-title>Sensor Calibrations</v-card-title>
                        <v-form ref="sensorForm">
                            <v-table>
                                <thead>
                                    <tr>
                                        <th>Calibration</th>
                                        <th>Current Value</th>
                                        <th>Pending Value</th>
                                        <th>Overrides</th>
                                        <th>Modified</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Offset at pH 7</td>
                                        <td>{{ sensorCalibrations?.ph?.offsetMvAtPh7 }}</td>
                                        <td>
                                            {{ pendingSensorCalibrations?.ph?.offsetMvAtPh7 }}<br />
                                            <small>{{ formatMetadataDate(pendingSensorCalibrationsMetadata?.ph?.offsetMvAtPh7) }}</small>
                                        </td>
                                        <td>
                                            <v-text-field
                                                v-model.number="editableSensorCalibrations.pHOffsetMvAtPh7"
                                                label="pH Offset at 7"
                                                density="compact"
                                                hide-details="auto"
                                                single-line
                                                variant="underlined"
                                                clearable
                                                suffix="mV"
                                                type="number"
                                                :hint="pHHintForValue(editableSensorCalibrations.pHOffsetMvAtPh7)"
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <v-icon v-if="editableSensorCalibrations.pHOffsetMvAtPh7 !== null" large color="green darken-2">mdi-check</v-icon>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>mV per pH (Slope)</td>
                                        <td>{{ sensorCalibrations?.ph?.slopeMvPerPh }}</td>
                                        <td>
                                            {{ pendingSensorCalibrations?.ph?.slopeMvPerPh }}<br />
                                            <small>{{ formatMetadataDate(pendingSensorCalibrationsMetadata?.ph?.slopeMvPerPh) }}</small>
                                        </td>
                                        <td>
                                            <v-text-field
                                                v-model.number="editableSensorCalibrations.pHSlope"
                                                label="pH Slope"
                                                density="compact"
                                                hide-details="auto"
                                                single-line
                                                variant="underlined"
                                                clearable
                                                suffix="mV / pH"
                                                type="number"
                                                :rules="[rules.sensorPhSlope]"
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <v-icon v-if="editableSensorCalibrations.pHSlope !== null" large color="green darken-2">mdi-check</v-icon>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>ORP Offset</td>
                                        <td>{{ sensorCalibrations?.orp?.offsetMv }}</td>
                                        <td>
                                            {{ pendingSensorCalibrations?.orp?.offsetMv }}<br />
                                            <small>{{ formatMetadataDate(pendingSensorCalibrationsMetadata?.orp?.offsetMv) }}</small>
                                        </td>
                                        <td>
                                            <v-text-field
                                                v-model.number="editableSensorCalibrations.orpOffset"
                                                label="ORP Offset"
                                                density="compact"
                                                hide-details="auto"
                                                single-line
                                                variant="underlined"
                                                clearable
                                                suffix="mV"
                                                type="number"
                                                :hint="orpHintForValue(editableSensorCalibrations.orpOffset)"
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <v-icon v-if="editableSensorCalibrations.orpOffset !== null" large color="green darken-2">mdi-check</v-icon>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Therm. Beta</td>
                                        <td>{{ sensorCalibrations?.therm?.beta }}</td>
                                        <td>
                                            {{ pendingSensorCalibrations?.therm?.beta }}<br />
                                            <small>{{ formatMetadataDate(pendingSensorCalibrationsMetadata?.therm?.beta) }}</small>
                                        </td>
                                        <td>
                                            <v-text-field
                                                v-model.number="editableSensorCalibrations.thermBeta"
                                                label="Beta"
                                                density="compact"
                                                hide-details="auto"
                                                single-line
                                                variant="underlined"
                                                clearable
                                                type="number"
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <v-icon v-if="editableSensorCalibrations.thermBeta !== null" large color="green darken-2">mdi-check</v-icon>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Therm. R0</td>
                                        <td>{{ sensorCalibrations?.therm?.R0 }}</td>
                                        <td>
                                            {{ pendingSensorCalibrations?.therm?.R0 }}<br />
                                            <small>{{ formatMetadataDate(pendingSensorCalibrationsMetadata?.therm?.R0) }}</small>
                                        </td>
                                        <td>
                                            <v-text-field
                                                v-model.number="editableSensorCalibrations.thermR0"
                                                label="R0"
                                                density="compact"
                                                hide-details="auto"
                                                single-line
                                                variant="underlined"
                                                clearable
                                                type="number"
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <v-icon v-if="editableSensorCalibrations.thermR0 !== null" large color="green darken-2">mdi-check</v-icon>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Therm. T0</td>
                                        <td>{{ sensorCalibrations?.therm?.T0 }}</td>
                                        <td>
                                            {{ pendingSensorCalibrations?.therm?.T0 }}<br />
                                            <small>{{ formatMetadataDate(pendingSensorCalibrationsMetadata?.therm?.T0) }}</small>
                                        </td>
                                        <td>
                                            <v-text-field
                                                v-model.number="editableSensorCalibrations.thermT0"
                                                label="T0"
                                                density="compact"
                                                hide-details="auto"
                                                single-line
                                                variant="underlined"
                                                clearable
                                                type="number"
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <v-icon v-if="editableSensorCalibrations.thermT0 !== null" large color="green darken-2">mdi-check</v-icon>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-table>
                        </v-form>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="d-flex justify-end">
                    <v-btn v-if="showSaveButton" class="mr-3" @click="discardChanges">Discard Changes</v-btn>
                    <v-btn v-if="showSaveButton" color="success" @click="saveChanges" :loading="savingChanges">Save Changes</v-btn>
                </v-col>
            </v-row>
            <br /><br />
            <v-row>
                <v-col>
                    <v-card>
                        <v-card-title>Device Calibrations</v-card-title>
                        <v-form ref="deviceForm">
                            <v-table>
                                <thead>
                                    <tr>
                                        <th>Calibration</th>
                                        <th>Current Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>pH Slope M</th>
                                        <td>{{ deviceCalibrations?.ph?.m }}</td>
                                    </tr>
                                    <tr>
                                        <th>pH Slope B</th>
                                        <td>{{ deviceCalibrations?.ph?.b }}</td>
                                    </tr>
                                    <tr>
                                        <th>ORP Slope M</th>
                                        <td>{{ deviceCalibrations?.orp?.m }}</td>
                                    </tr>
                                    <tr>
                                        <th>ORP Slope B</th>
                                        <td>{{ deviceCalibrations?.orp?.b }}</td>
                                    </tr>
                                </tbody>
                            </v-table>
                        </v-form>
                    </v-card>
                </v-col>
            </v-row>
        </div>
        <v-snackbar v-model="showSuccessSnackbar" variant="flat" color="success" :timeout="5000"
            >Updated device shadow. It may take 2 hours or more for the device to wake-up and accept the update.</v-snackbar
        >
        <v-snackbar v-model="showErrorSnackbar" variant="flat" color="red" :timeout="10000">{{ error }}</v-snackbar>
    </div>
</template>

<script setup lang="ts">
import { useApi } from '@/api'
import { ref, onMounted, computed, reactive, watch } from 'vue'
import { useBreadCrumbsStore } from '@/stores/breadCrumbs'
import type {
    DeviceCalibrationsV1,
    MonitorDetailsV1,
    PutMonitorCalibrationsV1,
    SensorCalibrationsMetadataV1,
    SensorCalibrationsV1,
    ShadowMetadataV1
} from '@general-galactic/crystal-api-client'
import { formatDistanceToNowStrict, fromUnixTime } from 'date-fns'
import { handleApiError, precision } from '@/lib/utils'

const breadCrumbsStore = useBreadCrumbsStore()

const props = defineProps({
    monitorId: {
        type: String,
        required: true
    }
})

type EditableSensorCalibrations = {
    pHSlope: number | null
    pHOffsetMvAtPh7: number | null
    orpOffset: number | null
    thermBeta: number | null
    thermR0: number | null
    thermT0: number | null
}

const formatMetadataDate = (metadata?: ShadowMetadataV1): string => {
    if (metadata?.timestamp === undefined) return ''
    const date = fromUnixTime(metadata.timestamp)
    return formatDistanceToNowStrict(date, { addSuffix: true })
}

const editableSensorCalibrations = reactive<EditableSensorCalibrations>({
    pHSlope: null,
    pHOffsetMvAtPh7: null,
    orpOffset: null,
    thermBeta: null,
    thermR0: null,
    thermT0: null
})

const loadingMonitorDetails = ref(false)
const monitorDetails = ref<MonitorDetailsV1 | undefined>()

const deviceCalibrations = ref<DeviceCalibrationsV1 | undefined>() // readonly

const sensorCalibrations = ref<SensorCalibrationsV1 | undefined>()
const pendingSensorCalibrations = ref<SensorCalibrationsV1 | undefined>()
const pendingSensorCalibrationsMetadata = ref<SensorCalibrationsMetadataV1 | undefined>()

const sensorForm = ref()
const savingChanges = ref(false)
const showSuccessSnackbar = ref(false)
const showErrorSnackbar = ref(false)
const error = ref<string | undefined>()

function setupEditableSensorCalibrationsWatch(key: keyof EditableSensorCalibrations) {
    watch(
        () => editableSensorCalibrations[key],
        () => {
            if ((editableSensorCalibrations[key] as unknown) === '') editableSensorCalibrations[key] = null
        }
    )
}

// These watches are used to catch the '' that is set when the user clicks the X button to clear the field. We set it to null instead.
setupEditableSensorCalibrationsWatch('pHSlope')
setupEditableSensorCalibrationsWatch('pHOffsetMvAtPh7')
setupEditableSensorCalibrationsWatch('orpOffset')
setupEditableSensorCalibrationsWatch('thermBeta')
setupEditableSensorCalibrationsWatch('thermR0')
setupEditableSensorCalibrationsWatch('thermT0')

const rules = ref({
    sensorPhSlope: (value: number): string | true => {
        if (value == null) return true
        if (value > 0) return 'pH Slope must be negative'
        if (value < -69.2 || value > -49.2) return 'pH Slope must be near -59.2'
        return true
    }
})

const showSaveButton = computed<boolean>(() => {
    if (!sensorForm.value?.validate()) return false
    if (allAreNull.value === true) return false
    return true
})

const allAreNull = computed(() => {
    if (editableSensorCalibrations.pHOffsetMvAtPh7 !== null) return false
    if (editableSensorCalibrations.pHSlope !== null) return false
    if (editableSensorCalibrations.orpOffset !== null) return false
    if (editableSensorCalibrations.thermBeta !== null) return false
    if (editableSensorCalibrations.thermR0 !== null) return false
    if (editableSensorCalibrations.thermT0 !== null) return false
    return true
})

const discardChanges = () => {
    editableSensorCalibrations.pHOffsetMvAtPh7 = null
    editableSensorCalibrations.pHSlope = null
    editableSensorCalibrations.orpOffset = null
    editableSensorCalibrations.thermBeta = null
    editableSensorCalibrations.thermR0 = null
    editableSensorCalibrations.thermT0 = null
}

const saveChanges = async () => {
    if (!sensorForm.value?.validate()) return

    const data: PutMonitorCalibrationsV1 = {
        sensorMvPerPh: undefined,
        sensorPhOffsetMv: undefined,
        sensorORPOffsetMv: undefined,
        sensorThermBeta: undefined,
        sensorThermR0: undefined,
        sensorThermT0: undefined
    }

    if (editableSensorCalibrations.pHSlope !== null) data.sensorMvPerPh = editableSensorCalibrations.pHSlope
    if (editableSensorCalibrations.pHOffsetMvAtPh7 !== null) data.sensorPhOffsetMv = editableSensorCalibrations.pHOffsetMvAtPh7
    if (editableSensorCalibrations.orpOffset !== null) data.sensorORPOffsetMv = editableSensorCalibrations.orpOffset
    if (editableSensorCalibrations.thermBeta !== null) data.sensorThermBeta = editableSensorCalibrations.thermBeta
    if (editableSensorCalibrations.thermR0 !== null) data.sensorThermR0 = editableSensorCalibrations.thermR0
    if (editableSensorCalibrations.thermT0 !== null) data.sensorThermT0 = editableSensorCalibrations.thermT0

    handleApiError(
        async () => {
            error.value = undefined
            savingChanges.value = true

            const result = await useApi().putMonitorCalibrationsV1({
                monitorId: props.monitorId,
                putMonitorCalibrationsV1: data
            })

            console.log('SAVED CALIBRATIONS', result)
            showSuccessSnackbar.value = true
        },
        async () => {
            savingChanges.value = false
            getMonitorDetails()
        }
    )
}

const getMonitorDetails = async () => {
    try {
        loadingMonitorDetails.value = true
        monitorDetails.value = await useApi().getMonitorDetailsV1({ monitorId: props.monitorId })

        deviceCalibrations.value = monitorDetails.value?.deviceShadow?.state?.reported?.calibrationsV1?.$device

        sensorCalibrations.value = monitorDetails.value?.deviceShadow?.state?.reported?.calibrationsV1?.sensor
        pendingSensorCalibrations.value = monitorDetails.value?.deviceShadow?.state?.desired?.calibrationsV1?.sensor
        pendingSensorCalibrationsMetadata.value = monitorDetails.value?.deviceShadow?.metadata?.desired?.calibrationsV1?.sensor
    } finally {
        loadingMonitorDetails.value = false
    }
}

onMounted(() => {
    getMonitorDetails()
    breadCrumbsStore.$patch({
        items: [
            {
                text: 'Monitors'
            },
            {
                text: String(props.monitorId),
                to: {
                    name: 'MonitorDetails',
                    params: {
                        monitorId: props.monitorId
                    }
                }
            },
            {
                text: 'Calibrations'
            }
        ]
    })
})

const orpHintForValue = (proposedValue: number | null): string | undefined => {
    if (proposedValue === null) return undefined

    const currentValue = sensorCalibrations.value?.orp?.offsetMv ?? 0

    const delta = proposedValue - currentValue
    if (delta === 0) return undefined

    if (delta < 0) return `Increase readings by ${Math.abs(delta)} mV`
    return `Decrease readings by ${delta}`
}

const pHHintForValue = (proposedValue: number | null): string | undefined => {
    if (proposedValue === null) return undefined

    const currentValue = sensorCalibrations.value?.ph?.offsetMvAtPh7 ?? 0
    const slopeMvPerPh = sensorCalibrations.value?.ph?.slopeMvPerPh ?? -59.02

    const deltaMv = proposedValue - currentValue
    if (deltaMv === 0) return undefined

    const deltaPh = precision(deltaMv / slopeMvPerPh, 2)

    if (deltaPh < 0) return `Increase readings by ${Math.abs(deltaPh)} pH`
    return `Decrease readings by ${deltaPh} pH`
}
</script>
