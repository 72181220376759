<template>
    <div v-if="loadingHistory">
        <v-progress-circular indeterminate size="36"></v-progress-circular>
    </div>
    <v-table v-else-if="history && history.length > 0">
        <thead>
            <tr>
                <th class="text-left pl-4">Serial Number</th>
                <th class="text-left pl-4">Type</th>
                <th class="text-left pl-4">Start</th>
                <th class="text-left pl-4">End</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(record, index) in history" :key="index">
                <td class="py-2">
                    <sensor-button :sensor="{ serialNumber: record.serialNumber, type: record.sensorType }" />
                </td>
                <td>{{ record.sensorType }}</td>
                <td>
                    {{ record.attachedDate && format(record.attachedDate, 'Pp') }}
                    <small>( {{ formatDistanceToNow(record.attachedDate, { addSuffix: true }) }} )</small>
                </td>
                <td>
                    <span v-if="record.detachedDate"
                        >{{ format(record.detachedDate, 'Pp') }} <small>( {{ formatDistanceToNow(record.detachedDate, { addSuffix: true }) }} )</small></span
                    >
                </td>
            </tr>
        </tbody>
    </v-table>
</template>

<script lang="ts" setup>
import { useApi } from '@/api'
import { onMounted, ref } from 'vue'
import { format } from 'date-fns-tz'
import { formatDistanceToNow } from 'date-fns'
import type { MonitorSensorRecordV1 } from '@general-galactic/crystal-api-client'
import SensorButton from '../buttons/SensorButton.vue'

const loadingHistory = ref(false)
const history = ref<MonitorSensorRecordV1[] | undefined>()

const props = defineProps({
    monitorId: {
        type: String,
        required: true
    }
})

onMounted(async () => {
    fetchHistory()
})

const fetchHistory = async () => {
    try {
        loadingHistory.value = true
        const result = await useApi().getMonitorSensorHistoryV1({ monitorId: props.monitorId })
        history.value = result.history
    } finally {
        loadingHistory.value = false
    }
}

defineExpose({ fetchHistory })
</script>
