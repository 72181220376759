import type { ChartDataStreams, ChartsKeys, DataStreamValues } from '@general-galactic/crystal-webapp-utils'
import { useApi } from '@/api'
import type {
    ChartRangesV1,
    GetMonitorChartV3Request,
    GetVesselChartV3Request,
    GetVesselWaterReportChartsV1Request,
    MonitorChartsV3
} from '@general-galactic/crystal-api-client'

export type CrystalAPIMonitorCharts = {
    charts?: ChartDataStreams
    startDate: Date
    endDate: Date
    bucketInterval: Duration
    source: string
    ranges?: ChartRangesV1
}

export class CrystalAPIMonitorChartFetcher {
    async getVesselChart(options: GetVesselChartV3Request): Promise<CrystalAPIMonitorCharts> {
        const result = await useApi().getVesselChartV3({
            ...options
        })
        return this.convertV3(result)
    }

    async getMonitorChart(options: GetMonitorChartV3Request): Promise<CrystalAPIMonitorCharts> {
        const result = await useApi().getMonitorChartV3({
            ...options
        })
        return this.convertV3(result)
    }

    async getWaterReportChart(options: GetVesselWaterReportChartsV1Request): Promise<ChartDataStreams> {
        const result = await useApi().getVesselWaterReportChartsV1({
            ...options
        })

        const { date, ...others } = result

        return {
            dates: date as unknown as string[],
            ...(others as unknown[]) // TODO: need to fix the API generated types - they fucked
        }
    }

    private convertV3(chartData: MonitorChartsV3): CrystalAPIMonitorCharts {
        const output: CrystalAPIMonitorCharts = {
            startDate: chartData.startDate,
            endDate: chartData.endDate,
            bucketInterval: chartData.bucketInterval,
            source: chartData.source,
            ranges: chartData.ranges
        }

        if (chartData.chart) {
            const { sampleDate, ...others } = chartData.chart

            // TODO: fix it so we don't have to lie about the types
            const mappedOthers = others as { [Property in ChartsKeys]?: DataStreamValues }

            output.charts = {
                dates: sampleDate as unknown as string[],
                ...mappedOthers
            }
        }

        return output
    }
}
