<template>
    <div class="smallVesselDisc" @click="click" :class="{ selectable }">
        <img class="discBackground" :src="discImage" />
        <div class="discContents">
            <div v-if="vessel?.oemId" class="vesseloem">{{ vessel?.oemId }}</div>
            <h2>{{ vessel?.name }}</h2>
            <div class="vesseltype">{{ vessel?.type }}</div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { WaterStatusColor, type AccountVesselV1 } from '@general-galactic/crystal-api-client'
import { computed, type PropType } from 'vue'

const props = defineProps({
    vessel: {
        type: Object as PropType<AccountVesselV1>
    },
    selectable: {
        type: Boolean,
        default: false
    }
})

const emit = defineEmits(['click'])

const BLUE_IMAGE = '/discs/disc-blue.svg'
const ORANGE_IMAGE = '/discs/disc-orange.svg'
const RED_IMAGE = '/discs/disc-red.svg'
const GRAY_IMAGE = '/discs/disc-gray.svg'

const discImage = computed(() => {
    if (props.vessel?.status?.discElements?.waterStatusColor === WaterStatusColor.Blue) {
        return BLUE_IMAGE
    } else if (props.vessel?.status?.discElements?.waterStatusColor === WaterStatusColor.Orange) {
        return ORANGE_IMAGE
    } else if (props.vessel?.status?.discElements?.waterStatusColor === WaterStatusColor.Red) {
        return RED_IMAGE
    }
    return GRAY_IMAGE
})

const click = () => {
    if (!props.selectable) return
    emit('click', props.vessel)
}
</script>

<style lang="scss" scoped>
.smallVesselDisc {
    position: relative;
    display: inline-block;
    width: 140px;
    height: 140px;
    transition: 0.3s transform;
    margin: 10px;

    .discBackground {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        transition: 0.3s transform;
    }

    .discContents {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: white;

        H2 {
            z-index: 1;
            font-size: 20px;
            text-align: center;
        }

        .vesseltype {
            z-index: 1;
        }

        .vesseloem {
            z-index: 1;
        }
    }
}

.selectable.smallVesselDisc {
    cursor: pointer;

    &:hover {
        transform: scale3d(1.1, 1.1, 1);

        .discBackground {
            transform: rotate(15deg);
        }
    }
}
</style>
