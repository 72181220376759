<template>
    <v-card :min-width="800">
        <v-card-title>
            <h3>Edit Vessel #{{ vessel?.vesselId }}</h3>
        </v-card-title>
        <v-card-text>
            <v-container>
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="v$.vesselName.$model"
                            label="Vessel Name"
                            required
                            :error-messages="vuelidateErrors(v$.vesselName)"
                            @input="v$.vesselName.$touch"
                            @blur="v$.vesselName.$touch"
                        />
                    </v-col>
                    <v-col>
                        <vessel-type-select v-model="v$.vesselType.$model" :error-messages="vuelidateErrors(v$.vesselType)" @blur="v$.vesselType.$touch" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="v$.vesselVolumeGallons.$model"
                            label="Volume"
                            required
                            type="number"
                            suffix="gal."
                            :error-messages="vuelidateErrors(v$.vesselVolumeGallons)"
                            @input="v$.vesselVolumeGallons.$touch"
                            @blur="v$.vesselVolumeGallons.$touch"
                        />
                    </v-col>
                    <v-col>
                        <vessel-protocol-select
                            v-model="v$.vesselProtocol.$model"
                            :vessel-type="v$.vesselType.$model ?? VesselTypes.Pool"
                            :vessel-oem="vessel?.oemId"
                            :error-messages="vuelidateErrors(v$.vesselProtocol)"
                            @blur="v$.vesselProtocol.$touch"
                            ref="protocol"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="v$.vesselLocation.line1.$model"
                            label="Address Line 1"
                            required
                            :error-messages="vuelidateErrors(v$.vesselLocation.line1)"
                            @input="v$.vesselLocation.line1.$touch"
                            @blur="v$.vesselLocation.line1.$touch"
                        />
                    </v-col>
                    <v-col>
                        <v-text-field
                            v-model="v$.vesselLocation.line2.$model"
                            label="Address Line 2"
                            :error-messages="vuelidateErrors(v$.vesselLocation.line2)"
                            @input="v$.vesselLocation.line2.$touch"
                            @blur="v$.vesselLocation.line2.$touch"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="v$.vesselLocation.locality.$model"
                            label="City"
                            required
                            :error-messages="vuelidateErrors(v$.vesselLocation.locality)"
                            @input="v$.vesselLocation.locality.$touch"
                            @blur="v$.vesselLocation.locality.$touch"
                        />
                    </v-col>
                    <v-col>
                        <v-text-field
                            v-model="v$.vesselLocation.administrativeAreaLevel1.$model"
                            label="State"
                            :error-messages="vuelidateErrors(v$.vesselLocation.administrativeAreaLevel1)"
                            @input="v$.vesselLocation.administrativeAreaLevel1.$touch"
                            @blur="v$.vesselLocation.administrativeAreaLevel1.$touch"
                        />
                    </v-col>
                    <v-col>
                        <v-text-field
                            v-model="v$.vesselLocation.postalCode.$model"
                            label="Postal Code"
                            :error-messages="vuelidateErrors(v$.vesselLocation.postalCode)"
                            @input="v$.vesselLocation.postalCode.$touch"
                            @blur="v$.vesselLocation.postalCode.$touch"
                        />
                    </v-col>
                </v-row>
            </v-container>
            <v-overlay :model-value="updatingVessel" class="align-center justify-center">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue-darken-1" @click="cancel">Close</v-btn>
            <v-btn color="blue-darken-1" @click="submit" :disabled="v$.$invalid">Update Vessel</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script lang="ts" setup>
import { useApi } from '@/api'
import { computed, reactive } from 'vue'
import { ref } from 'vue'
import VesselTypeSelect from './VesselTypeSelect.vue'
import VesselProtocolSelect from './VesselProtocolSelect.vue'
import { required, minValue, maxValue, maxLength } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { handleApiError, vuelidateErrors } from '@/lib/utils'
import { type PostVesselLocationV1, type ProtocolV2, VesselTypes } from '@general-galactic/crystal-api-client'
import { useVesselStore } from '@/stores/vessel'
import { watch } from 'vue'
import { onMounted } from 'vue'

const vesselStore = useVesselStore()
const protocol = ref<InstanceType<typeof VesselProtocolSelect>>()

const emit = defineEmits<{
    (event: 'vesselUpdated'): void
    (event: 'cancel'): void
}>()

const vessel = computed(() => {
    return vesselStore.vessel
})

onMounted(() => {
    syncVesselToState()
    v$.value.$validate()
})

watch(vessel, () => {
    syncVesselToState()
})

const syncVesselToState = () => {
    if (vessel.value === undefined) {
        state.vesselName = undefined
        state.vesselType = undefined
        state.vesselVolumeGallons = undefined
        state.vesselProtocol = undefined
        state.vesselLocation = undefined
        return
    }

    state.vesselName = vessel.value.name
    state.vesselType = vessel.value.type
    state.vesselVolumeGallons = vessel.value.volumeGallons
    state.vesselLocation = vessel.value.location

    if (vessel.value.protocolName) {
        // This one is complicated because it needs to load protocols from the
        // server before the protocolName can be used to select a protocol in the control
        protocol.value?.setProtocolFromId(vessel.value.protocolName)
    }
}

const updatingVessel = ref(false)

const state = reactive<{
    vesselName: string | undefined
    vesselType: VesselTypes | undefined
    vesselVolumeGallons: number | undefined
    vesselProtocol: ProtocolV2 | undefined
    vesselLocation: Partial<PostVesselLocationV1> | undefined
}>({
    vesselName: undefined,
    vesselType: undefined,
    vesselVolumeGallons: undefined,
    vesselProtocol: undefined,
    vesselLocation: {
        line1: undefined,
        line2: undefined,
        locality: undefined,
        administrativeAreaLevel1: undefined,
        postalCode: undefined
    }
})

const rules = {
    vesselName: { required, maxLength: maxLength(40) },
    vesselType: { required },
    vesselVolumeGallons: { required, minValue: minValue(160), maxValue: maxValue(50000) },
    vesselProtocol: { required },
    vesselLocation: {
        line1: { required, maxLength: maxLength(255) },
        line2: { maxLength: maxLength(255) },
        locality: { required, maxLength: maxLength(255) },
        administrativeAreaLevel1: { required, maxLength: maxLength(2) },
        postalCode: { required, maxLength: maxLength(9) }
    }
}

const v$ = useVuelidate(rules, state)

const submit = async () => {
    const isFormCorrect = await v$.value.$validate()
    if (!isFormCorrect) return

    const accountId = vessel.value?.accountId
    const vesselId = vessel.value?.vesselId

    if (!accountId || !vesselId) return

    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    await handleApiError(
        async () => {
            updatingVessel.value = true
            await useApi().updateVesselV1({
                accountId,
                vesselId,
                postVesselV1: {
                    name: state.vesselName!,
                    type: state.vesselType!,
                    volumeGallons: state.vesselVolumeGallons!,
                    protocolName: state.vesselProtocol!.id,
                    location: state.vesselLocation! as Required<PostVesselLocationV1>
                }
            })
            emit('vesselUpdated')
        },
        async () => {
            updatingVessel.value = false
        }
    )
    /* eslint-enable @typescript-eslint/no-non-null-assertion */
}

const cancel = () => {
    emit('cancel')
}
</script>

<style lang="scss" scoped></style>
