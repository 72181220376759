<template>
    <v-menu v-if="subscriptionGrantStatus.status === MonitorSubscriptionGrantStatusesV1.Available">
        <template v-slot:activator="{ props }">
            <v-btn color="success" append-icon="mdi-menu-down" v-bind="props">1 Year Subscription Included</v-btn>
        </template>
        <v-list>
            <v-list-item title="Remove Included Subscription" prepend-icon="mdi-block-helper" @click.stop="">
                <v-dialog v-model="showConfirmBlockDialog" activator="parent" max-width="800">
                    <v-card>
                        <v-form v-model="blockingFormValid" @submit.prevent="blockSubscriptionGrant">
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <h3>
                                            Are you sure you want to remove this monitor's included subscription? This means a customer who uses this monitor
                                            will NOT get a free 1 year subscription.
                                        </h3>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <p class="mb-2">What's the reason for removing this monitor's included subscription?</p>
                                        <v-btn-toggle v-model="blockingReason" variant="outlined" mandatory>
                                            <v-btn value="replacement"> Replacement Monitor </v-btn>
                                            <v-btn value="other"> Block / Prevent Use </v-btn>
                                        </v-btn-toggle>
                                    </v-col>
                                </v-row>
                                <v-row v-if="blockingReason === 'other'">
                                    <v-col>
                                        <v-textarea
                                            v-model="blockingReasonText"
                                            label="Please explain your reasoning"
                                            class="mt-3"
                                            counter="250"
                                            :rules="validateBlockingTextRules"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn
                                    type="submit"
                                    color="red-darken-1"
                                    :loading="waitingForStatusUpdate"
                                    :disabled="blockingReason === 'other' && !blockingFormValid"
                                    >Remove Included Subscription</v-btn
                                >
                                <v-btn color="success" @click="showConfirmBlockDialog = false">Cancel</v-btn>
                            </v-card-actions>
                        </v-form>
                    </v-card>
                </v-dialog>
            </v-list-item>
        </v-list>
    </v-menu>

    <v-menu v-else-if="subscriptionGrantStatus.status === MonitorSubscriptionGrantStatusesV1.Used">
        <template v-slot:activator="{ props }">
            <v-btn color="orange" append-icon="mdi-menu-down" v-bind="props">Included Subscription Used</v-btn>
        </template>
        <v-list>
            <v-list-item>
                <p>
                    Granted on {{ formatDate(subscriptionGrantStatus.statusDate) }}
                    <span v-if="subscriptionGrantStatus.accountId">to Account #{{ subscriptionGrantStatus.accountId }}</span
                    ><span v-if="subscriptionGrantStatus.vesselId"> / Vessel #{{ subscriptionGrantStatus.vesselId }}</span>
                </p>
            </v-list-item>
            <v-divider />
            <!-- <v-list-item title="View Vessel"></v-list-item> -->
            <v-list-item title="Restore Included Subscription" prepend-icon="mdi-restore" @click.stop="">
                <v-dialog v-model="showConfirmRestoreDialog" activator="parent" max-width="800">
                    <v-card>
                        <v-card-text>
                            <h3>
                                Are you sure you want to restore this monitor's included subscription? This means a customer who uses this monitor will be able
                                to get a free 1 year subscription.
                            </h3>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn color="red-darken-1" :loading="waitingForStatusUpdate" @click="restoreSubscriptionGrant">YES, RESTORE IT</v-btn>
                            <v-btn color="success" @click="showConfirmRestoreDialog = false">NO, CANCEL</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-list-item>
        </v-list>
    </v-menu>

    <v-menu v-else>
        <template v-slot:activator="{ props }">
            <v-btn color="red" append-icon="mdi-menu-down" v-bind="props">Blocked / No Included Subscription</v-btn>
        </template>
        <v-list>
            <v-list-item>
                <strong>Blocked on {{ formatDate(subscriptionGrantStatus.statusDate) }}</strong>
                <p class="blockedReason" v-if="subscriptionGrantStatus.text">{{ subscriptionGrantStatus.text }}</p>
            </v-list-item>
            <v-divider />
            <v-list-item title="Restore Included Subscription" prepend-icon="mdi-restore">
                <v-dialog v-model="showConfirmRestoreDialog" activator="parent" max-width="800">
                    <v-card>
                        <v-card-text>
                            <h3>
                                Are you sure you want to restore this monitor's included subscription? This means a customer who uses this monitor will be able
                                to get a free 1 year subscription.
                            </h3>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn color="red-darken-1" :loading="waitingForStatusUpdate" @click="restoreSubscriptionGrant">YES, RESTORE IT</v-btn>
                            <v-btn color="success" @click="showConfirmRestoreDialog = false">NO, CANCEL</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script lang="ts" setup>
import { ref, type PropType } from 'vue'
import { MonitorSubscriptionGrantStatusesV1, type MonitorSubscriptionGrantStatusV1 } from '@general-galactic/crystal-api-client'
import { formatDate, handleApiError } from '@/lib/utils'
import { useApi } from '@/api'

const props = defineProps({
    monitorId: {
        type: String,
        required: true
    },
    subscriptionGrantStatus: {
        type: Object as PropType<MonitorSubscriptionGrantStatusV1>,
        required: true
    }
})

const emit = defineEmits(['updateMonitor'])

const waitingForStatusUpdate = ref(false)
const showConfirmBlockDialog = ref(false)
const showConfirmRestoreDialog = ref(false)
const blockingReason = ref<'replacement' | 'other'>('replacement')
const blockingReasonText = ref<string>()
const blockingFormValid = ref(false)

const validateBlockingTextRules = [
    (value: string): string | true => {
        if (value === undefined || value.length === 0) return 'Required'
        if (value.length > 250) return 'Max 250 characters'
        return true
    }
]

const blockSubscriptionGrant = () => {
    handleApiError(
        async () => {
            waitingForStatusUpdate.value = true

            const status = blockingReason.value === 'replacement' ? MonitorSubscriptionGrantStatusesV1.NotIncluded : MonitorSubscriptionGrantStatusesV1.Blocked
            const reason = blockingReason.value === 'replacement' ? undefined : blockingReasonText.value

            await useApi().putMonitorIncludedSubscriptionStatusV1({
                monitorId: props.monitorId,
                putIncludedSubscriptionStatusRequestV1: {
                    status,
                    reason
                }
            })
            emit('updateMonitor')
            showConfirmBlockDialog.value = false
            blockingReasonText.value = undefined
        },
        async () => {
            waitingForStatusUpdate.value = false
        }
    )
}

const restoreSubscriptionGrant = () => {
    handleApiError(
        async () => {
            waitingForStatusUpdate.value = true
            await useApi().putMonitorIncludedSubscriptionStatusV1({
                monitorId: props.monitorId,
                putIncludedSubscriptionStatusRequestV1: {
                    status: MonitorSubscriptionGrantStatusesV1.Available
                }
            })
            emit('updateMonitor')
            showConfirmRestoreDialog.value = false
        },
        async () => {
            waitingForStatusUpdate.value = false
        }
    )
}
</script>

<style scoped lang="scss">
.blockedReason {
    max-width: 500px;
    overflow-wrap: break-word;
}
</style>
