<script lang="ts" setup>
import { useAuth0 } from '@auth0/auth0-vue'

const { loginWithRedirect, error } = useAuth0()

const login = () => {
    loginWithRedirect({
        appState: {
            target: '/'
        }
    })
}
</script>

<template>
    <div class="login text-center pt-14">
        <v-row>
            <v-col md="6" offset-md="3" lg="4" offset-lg="4">
                <h1 class="mb-10">CRYSTAL</h1>
                <v-btn rounded @click="login" color="primary">Login</v-btn>
                <v-alert v-if="error" type="error" class="mt-8">{{ error }}</v-alert>
            </v-col>
        </v-row>
    </div>
</template>
