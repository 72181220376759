<template>
    <v-tooltip :text="failureType.title">
        <template v-slot:activator="{ props }">
            <v-chip v-bind="props" :color="colorForFailureType(failureType.failureType)">{{ failureType.shortTitle }}</v-chip>
        </template>
    </v-tooltip>
</template>

<script lang="ts" setup>
import { type SensorFailureTypeV1 } from '@general-galactic/crystal-api-client'
import { type PropType } from 'vue'

defineProps({
    failureType: {
        type: Object as PropType<SensorFailureTypeV1>,
        required: true
    }
})

const colorForFailureType = (failureType: string) => {
    if (failureType === 'other') return undefined
    return 'primary'
}
</script>
