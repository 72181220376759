import { VesselTypes } from '@general-galactic/crystal-api-client'

export type VesselTypeItem = {
    id: string
    title: string
}

export const ALL_VESSEL_TYPES: VesselTypeItem[] = [
    {
        id: VesselTypes.Hottub,
        title: 'Hot Tub'
    },
    {
        id: VesselTypes.Pool,
        title: 'Pool'
    },
    {
        id: VesselTypes.Swimspa,
        title: 'Swim Spa'
    }
]

export function titleForVesselType(vesselType: VesselTypes | string): string {
    if (vesselType === VesselTypes.Pool) return 'Pool'
    if (vesselType === VesselTypes.Hottub) return 'Hot Tub'
    if (vesselType === VesselTypes.Swimspa) return 'Swim Spa'
    return vesselType
}
