<template>
    <div class="sizeLimit" ref="sizeLimit">
        <div class="vesselDisc" @click="click" :class="{ selectable }">
            <img class="discBackground" :src="discImage" />
            <div v-if="discElements" class="discContents">
                <div class="vesselName">{{ discElements.name ?? '' }}</div>
                <div class="center">
                    <div class="temp" v-if="discElements.tempC">
                        <p>{{ tempCToTempF(discElements.tempC) }}</p>
                    </div>
                    <div v-if="discElements.text" class="actions">
                        {{ discElements.text }}
                    </div>
                </div>
                <div class="date">{{ discElements.lastUpdatedText ?? '' }}</div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { type AccountVesselV1, WaterStatusColor } from '@general-galactic/crystal-api-client'
import { computed, type PropType } from 'vue'
import { tempCToTempF } from '@general-galactic/chem-engine'

const props = defineProps({
    vessel: {
        type: Object as PropType<AccountVesselV1>
    },
    selectable: {
        type: Boolean,
        default: false
    }
})

const emit = defineEmits(['click'])

const BLUE_IMAGE = '/discs/disc-blue.svg'
const ORANGE_IMAGE = '/discs/disc-orange.svg'
const RED_IMAGE = '/discs/disc-red.svg'
const GRAY_IMAGE = '/discs/disc-gray.svg'

const discImage = computed(() => {
    if (props.vessel?.status?.discElements?.waterStatusColor === WaterStatusColor.Blue) {
        return BLUE_IMAGE
    } else if (props.vessel?.status?.discElements?.waterStatusColor === WaterStatusColor.Orange) {
        return ORANGE_IMAGE
    } else if (props.vessel?.status?.discElements?.waterStatusColor === WaterStatusColor.Red) {
        return RED_IMAGE
    }
    return GRAY_IMAGE
})

const discElements = computed(() => {
    return props.vessel?.status?.discElements
})

const click = () => {
    if (!props.selectable) return
    emit('click', props.vessel)
}
</script>

<style lang="scss" scoped>
.sizeLimit {
    width: 100%;
    max-width: 300px;
    max-height: 300px;
    min-width: 300px;
    min-height: 300px;
}

.vesselDisc {
    position: relative;
    display: inline-block;
    width: 100%;
    padding-top: 100%;
    height: 0;
    transition: 0.3s transform;

    .discBackground {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        transition: 0.3s transform;
        filter: drop-shadow(0 2px 1px rgb(0 0 0 / 0.2));
    }

    .discContents {
        position: absolute;
        top: 10%;
        left: 10%;
        right: 10%;
        bottom: 10%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        color: white;
        overflow: hidden;
    }

    .vesselName {
        max-width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 10px;
    }

    .temp {
        font-size: 80px;
        line-height: 68px;
        font-weight: 300;
        display: flex;
        justify-content: center;
        margin: 0;

        p {
            margin: 0;
            position: relative;
            display: inline-block;

            &::after {
                content: '°';
                position: absolute;
                top: 0;
                right: -20px;
                font-weight: 100;
                font-size: 60px;
            }
        }
    }
    .actions {
        margin: 0;
        font-size: 24px;
        font-weight: 300;
        text-align: center;
    }

    .date {
        font-size: 16px;
        font-weight: 300;
    }
}

.selectable.vesselDisc {
    cursor: pointer;

    &:hover {
        transform: scale3d(1.1, 1.1, 1);

        .discBackground {
            transform: rotate(15deg);
        }
    }
}
</style>
