<template>
    <v-dialog :modelValue="modelValue" @update:modelValue="syncVModel" max-width="800">
        <v-card :min-width="600" v-if="user">
            <v-card-title>
                <h3>Move {{ user.firstName ?? 'User' }} To Different Account</h3>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col class="d-flex align-center">
                            <p>Enter the account id to move {{ user?.firstName ?? 'this user' }} to</p>
                        </v-col>
                        <v-col>
                            <v-text-field v-model.number="moveToAccountId" label="Move to Account Id" required hide-details />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="d-flex align-center">
                            <p>The target account has these users:</p>
                        </v-col>
                        <v-col class="d-flex align-center">
                            <v-alert v-if="moveToAccountId === accountId" density="compact" type="error" text="That's the same account dude!"></v-alert>
                            <v-progress-circular v-else-if="loadingAccountUsers" indeterminate size="64"></v-progress-circular>
                            <v-alert
                                v-if="loadedAccountUsers && !targetAccountExists"
                                density="compact"
                                type="error"
                                text="This account doesn't exist"
                            ></v-alert>
                            <div v-else-if="loadedAccountUsers && targetAccountUsers && targetAccountUsers.length > 0">
                                <div v-for="user of targetAccountUsers.slice(0, 3)" :key="user.userId">
                                    <strong>{{ user.firstName }} {{ user.lastName }}</strong> - {{ user.email }}
                                </div>
                            </div>
                            <p v-else-if="loadedAccountUsers">no users</p>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn color="gray-darken-1" fill="clear" @click="syncVModel(false)">Cancel</v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="moveUser" :disabled="!canMoveUser" color="red" :loading="movingUser">Yes, Move User</v-btn>
            </v-card-actions>
        </v-card>
        <v-card v-else>
            <v-card-text>
                <h1>You must provide a user to use this feature</h1>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script lang="ts" setup>
import { useApi } from '@/api'
import { handleApiError, isApiNotFoundError } from '@/lib/utils'
import router from '@/router'
import type { AccountUserV1 } from '@general-galactic/crystal-api-client'
import type { PropType } from 'vue'
import { computed, ref, watch } from 'vue'

const props = defineProps({
    modelValue: {
        type: Boolean
    },
    accountId: {
        type: Number,
        required: true
    },
    user: {
        type: Object as PropType<AccountUserV1>
    }
})

const moveToAccountId = ref<number | string | undefined>()
const targetAccountUsers = ref<AccountUserV1[] | undefined>()
const loadingAccountUsers = ref(false)
const loadedAccountUsers = ref(false)
const targetAccountExists = ref(false)
const movingUser = ref(false)

const emit = defineEmits<{
    (event: 'update:modelValue', value: boolean): void
}>()

const syncVModel = (value: boolean) => {
    emit('update:modelValue', value)
}

watch(
    () => props.modelValue,
    () => {
        if (props.modelValue === false) {
            moveToAccountId.value = undefined
            targetAccountUsers.value = undefined
            loadedAccountUsers.value = false
        }
    }
)

const cleanMoveToAccountId = computed(() => {
    if (moveToAccountId.value === undefined) return undefined
    if (typeof moveToAccountId.value === 'string') return undefined
    if (moveToAccountId.value > 999999999) return undefined
    return moveToAccountId.value
})

watch(cleanMoveToAccountId, () => {
    const accountId = cleanMoveToAccountId.value

    if (accountId === undefined) {
        targetAccountUsers.value = undefined
        loadedAccountUsers.value = false
        targetAccountExists.value = false
        return
    }

    if (accountId === props.accountId) return

    loadAccountUsers(accountId)
})

const canMoveUser = computed(() => {
    if (cleanMoveToAccountId.value === undefined) return false
    if (cleanMoveToAccountId.value === props.accountId) return false
    if (loadedAccountUsers.value === false) return false
    if (targetAccountExists.value === false) return false
    return true
})

const loadAccountUsers = async (accountId: number) => {
    try {
        loadedAccountUsers.value = false
        loadingAccountUsers.value = true
        targetAccountUsers.value = undefined

        const { users } = await useApi().getAccountUsersV1({ accountId })

        loadedAccountUsers.value = true
        targetAccountUsers.value = users
        targetAccountExists.value = true
    } catch (error) {
        if (isApiNotFoundError(error)) {
            loadedAccountUsers.value = true
            targetAccountExists.value = false
        } else {
            handleApiError(() => {
                throw error
            })
        }
    } finally {
        loadingAccountUsers.value = false
    }
}

const moveUser = async () => {
    const targetAccountId = cleanMoveToAccountId.value
    const userId = props.user?.userId

    if (targetAccountId === undefined) return
    if (userId === undefined) return

    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    handleApiError(
        async () => {
            movingUser.value = true
            await useApi().putAccountUser({ accountId: targetAccountId, putAccountUserRequestV1: { userId } })
            syncVModel(false)
            router.push({ name: 'AccountDetails', params: { accountId: targetAccountId } })
        },
        async () => {
            movingUser.value = false
        }
    )
}
</script>

<style lang="scss" scoped></style>
