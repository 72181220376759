import type { AccountChemicalV1, ChemicalV2 } from '@general-galactic/crystal-api-client'

export function formatIngredientName(chemical: ChemicalV2 | AccountChemicalV1): string {
    if (chemical.chemicalType) {
        return chemical.chemicalType
    }

    if (chemical.otherChemicalName) {
        return `Other(${chemical.otherChemicalName})`
    }

    return '(no ingredient)'
}

export function formatBarcode(chemical: ChemicalV2 | AccountChemicalV1): string {
    return chemical.barcode ?? '(no barcode)'
}
