<template>
    <v-progress-circular v-if="loadingAllProtocols" indeterminate size="30"></v-progress-circular>
    <v-select
        v-else
        v-model="model"
        label="Protocol"
        :items="allProtocols"
        @update:modelValue="syncVModel"
        :item-props="itemProps"
        item-value="id"
        return-object
        @blur="$emit('blur')"
    ></v-select>
</template>

<script lang="ts" setup>
import { useApi } from '@/api'
import { handleApiError } from '@/lib/utils'
import type { ProtocolV2, PutMonitorClaimV1OemId, VesselTypes } from '@general-galactic/crystal-api-client'
import { onMounted, watch, type PropType } from 'vue'
import { ref } from 'vue'

const props = defineProps({
    modelValue: {
        type: Object as PropType<ProtocolV2>
    },
    vesselType: {
        type: String as PropType<VesselTypes>,
        required: true
    },
    vesselOem: {
        type: String as PropType<PutMonitorClaimV1OemId>,
        required: false
    }
})

const model = ref<ProtocolV2 | undefined>()
const loadingAllProtocols = ref(false)
const allProtocols = ref<ProtocolV2[] | undefined>()
const protocolId = ref<string | undefined>()

watch(
    () => props.modelValue,
    (value) => {
        model.value = value
    },
    { immediate: true }
)

watch(
    () => props.vesselType,
    (value) => {
        fetchProtocols(value)
    }
)

onMounted(() => {
    fetchProtocols(props.vesselType)
})

defineExpose({
    setProtocolFromId(_protocolId: string) {
        protocolId.value = _protocolId
        selectProtocolUsingId()
    }
})

const selectProtocolUsingId = () => {
    if (!allProtocols.value?.length) return // protocols not loaded yet - defer this update

    const fullProtocol = allProtocols.value.find((p) => p.id === protocolId.value)
    model.value = fullProtocol
    syncVModel()
}

watch(allProtocols, () => {
    selectProtocolUsingId() // Update the selected protocol object using the protocol id in case it was set before the protocols were loaded
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const itemProps = (item: Record<string, any>) => {
    // vuetify mandates that the function have the signature above
    return {
        title: `${item.title}${item.allowedByOem ? '' : ' (unsupported)'}`,
        subtitle: item.allowedByOem ? undefined : `Using this protocol may void the manufacturer's warranty`
    }
}

const fetchProtocols = async (vesselType: VesselTypes) => {
    await handleApiError(
        async () => {
            loadingAllProtocols.value = true
            const result = await useApi().getProtocolsV2({
                vesselType,
                vesselOemId: String(props.vesselOem),
                showAll: true
            })
            allProtocols.value = result.protocols

            if (props.modelValue?.id !== undefined) {
                const fullProtocol = allProtocols.value.find((p) => p.id === props.modelValue?.id)
                model.value = fullProtocol
                syncVModel()
            }
        },
        async () => {
            loadingAllProtocols.value = false
        }
    )
}

const emit = defineEmits(['update:modelValue', 'blur'])

const syncVModel = () => {
    emit('update:modelValue', model.value)
}
</script>
