<template>
    <v-row>
        <v-col md="4"> Failure Type: </v-col>
        <v-col>
            <v-progress-circular v-if="loadingFailureTypes" indeterminate></v-progress-circular>
            <v-select v-else v-model="v$.failureType.$model" label="Failure Type" :items="failureTypes" item-value="failureType"></v-select>
        </v-col>
    </v-row>
    <v-row>
        <v-col md="4"> Failure Start Date: </v-col>
        <v-col>
            <v-row>
                <v-col
                    ><v-text-field
                        :error-messages="vuelidateErrors(v$.date)"
                        label="Failure Start Date"
                        v-model="v$.date.$model"
                        @input="v$.date.$touch"
                        @blur="v$.date.$touch"
                        type="date"
                /></v-col>
                <v-col
                    ><v-text-field
                        :error-messages="vuelidateErrors(v$.time)"
                        label="Failure Start Time"
                        v-model="v$.time.$model"
                        @input="v$.time.$touch"
                        @blur="v$.time.$touch"
                        type="time"
                /></v-col>
            </v-row>
        </v-col>
    </v-row>
    <v-row>
        <v-col md="4"> Notes: </v-col>
        <v-col>
            <v-textarea v-model="v$.notes.$model" label="Notes" :error-messages="vuelidateErrors(v$.notes)" @input="v$.notes.$touch" @blur="v$.notes.$touch" />
        </v-col>
    </v-row>
</template>

<script lang="ts" setup>
import { reactive } from 'vue'
import { ref } from 'vue'
import { required, maxLength } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { handleApiError, vuelidateErrors } from '@/lib/utils'
import type { MonitorFailureTypeV1 } from '@general-galactic/crystal-api-client'
import { onMounted } from 'vue'
import { useApi } from '@/api'
import { watch } from 'vue'
import { constructMergedDate, isPastDate, isPastTime, type VuetifyDateTimeState } from '@/lib/vuetifyDateUtils'

const loadingFailureTypes = ref(false)
const failureTypes = ref<MonitorFailureTypeV1[] | undefined>()

export type FailureFormData = {
    failureType: string
    notes?: string
    failureStartDate?: Date
}

export type FormStateUpdate = {
    state?: FailureFormData
    invalid: boolean
}

const emit = defineEmits<{
    (event: 'formStateUpdate', value: FormStateUpdate): void
}>()

type FailureFormState = {
    failureType: string | undefined
    notes: string | undefined
} & VuetifyDateTimeState

const state = reactive<FailureFormState>({
    failureType: undefined,
    notes: undefined,
    date: undefined,
    time: undefined
})

const rules = {
    failureType: { required, maxLength: maxLength(50) },
    notes: { maxLength: maxLength(2000) },
    date: { isPastDate },
    time: { isPastTime }
}

const v$ = useVuelidate(rules, state)

watch(
    state,
    () => {
        const event: FormStateUpdate = {
            invalid: v$.value.$invalid
        }

        if (state.failureType !== undefined) {
            // Only return state if everything required is provided
            event.state = {
                failureType: state.failureType,
                failureStartDate: constructMergedDate(state),
                notes: state.notes
            }
        }

        emit('formStateUpdate', event)
    },
    { immediate: true }
)

onMounted(async () => {
    loadFailureTypes()
})

const loadFailureTypes = async () => {
    handleApiError(
        async () => {
            loadingFailureTypes.value = true
            const failureTypesResult = await useApi().getMonitorFailureTypesV1()
            failureTypes.value = failureTypesResult.failureTypes
        },
        async () => {
            loadingFailureTypes.value = false
        }
    )
}
</script>

<style lang="scss" scoped></style>
