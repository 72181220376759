<template>
    <v-progress-circular v-if="loadingAllOems" indeterminate size="30"></v-progress-circular>
    <v-select
        v-else
        v-model="model"
        label="Vessel OEM"
        :items="listItems"
        @update:modelValue="syncVModel"
        item-value="id"
        return-object
        @blur="$emit('blur')"
    ></v-select>
</template>

<script lang="ts" setup>
import { useApi } from '@/api'
import { type OemEntryV1 } from '@general-galactic/crystal-api-client'
import { handleApiError } from '@/lib/utils'
import { computed, onMounted, ref, watch } from 'vue'

const props = defineProps({
    modelValue: {
        type: String,
        required: false
    }
})

interface ListItem {
    title: string
    value?: string
}

const notSelected: ListItem = {
    title: '<none>',
    value: undefined
}

const model = ref<ListItem>(notSelected)
const loadingAllOems = ref(false)
const allOems = ref<OemEntryV1[] | undefined>()

const listItems = computed(() => {
    const items = [notSelected]

    const oems = allOems.value ?? []
    for (const oem of oems) {
        items.push({
            title: oem.oemId,
            value: oem.oemId
        })
    }
    return items
})

onMounted(() => {
    fetchOems()
})

watch(
    () => props.modelValue,
    (value) => {
        const selected = listItems.value.find((item) => item.value == value)
        if (selected) {
            model.value = selected
        }
    },
    { immediate: true }
)

const fetchOems = async () => {
    await handleApiError(
        async () => {
            loadingAllOems.value = true
            const result = await useApi().getOemsV1()
            allOems.value = result.oems

            if (props.modelValue !== undefined) {
                const selected = listItems.value.find((item) => item.value == props.modelValue)
                if (selected) {
                    model.value = selected
                }
                syncVModel()
            }
        },
        async () => {
            loadingAllOems.value = false
        }
    )
}

const emit = defineEmits(['update:modelValue', 'blur'])

const syncVModel = () => {
    emit('update:modelValue', model.value.value)
}
</script>
