<template>
    <div v-if="state === 'connecting'">Connecting</div>
    <v-table v-else-if="state === 'connected'">
        <thead>
            <tr>
                <th>Sample Date</th>
                <th>pH</th>
                <th>ORP</th>
                <th>Temp. C</th>
                <th>Battery</th>
                <th>RSSI</th>
                <th>Other Keys</th>
            </tr>
        </thead>
        <tbody v-if="samples.length === 0">
            <tr>
                <td colspan="6">Waiting for Samples</td>
            </tr>
        </tbody>
        <tbody v-else>
            <tr v-for="sample of samples" :key="sample.sampleEpoch">
                <td>{{ formatDate(new Date(sample.sampleDate)) }}</td>
                <td>{{ sample.ph }}</td>
                <td>{{ sample.orpMv }}</td>
                <td>{{ sample.waterTempC }}</td>
                <td>{{ sample.batteryMv }}</td>
                <td>{{ sample.wifiRssi }}</td>
                <td>{{ sample.otherKeys }}</td>
            </tr>
        </tbody>
    </v-table>
    <div v-else>Failed to connect: {{ error }}</div>
</template>

<script lang="ts" setup>
import { useApi } from '@/api'
import mqtt from 'mqtt'
import { useBreadCrumbsStore } from '@/stores/breadCrumbs'
import { onMounted, onUnmounted, ref } from 'vue'
import type { MonitorLiveStreamV1, MonitorPreviousSampleV1 } from '@general-galactic/crystal-api-client'
import { formatDate } from '@/lib/utils'

const breadCrumbsStore = useBreadCrumbsStore()

const props = defineProps({
    monitorId: {
        type: String,
        required: true
    }
})

type LiveSample = {
    sampleEpoch: number
    sampleDate: Date
    ph?: number
    orpMv?: number
    waterTempC?: number
    batteryMv?: number
    wifiRssi?: number
    cpuTempC?: number
    otherKeys?: Record<string, unknown>
}

const samples = ref<LiveSample[]>([])
const state = ref<'connecting' | 'connected' | 'failed'>('connecting')
const error = ref<string | undefined>()
const liveInfo = ref<MonitorLiveStreamV1 | undefined>()

let client: mqtt.MqttClient | undefined

async function _fetchLiveStreamInfo() {
    liveInfo.value = await useApi().getMonitorLiveStreamV1({ monitorId: props.monitorId })

    if (liveInfo.value.previousSamples) {
        for (const sample of liveInfo.value.previousSamples) {
            samples.value.push(_toDisplayableSample(sample))
        }
        samples.value = _sort(samples.value)
    }
}

function _sort(samples: LiveSample[]): LiveSample[] {
    samples.sort((a, b) => {
        return b.sampleEpoch - a.sampleEpoch
    })
    return samples
}

function _toDisplayableSample(sample: MonitorPreviousSampleV1): LiveSample {
    const { sampleEpoch, ph, orpMv, waterTempC, wifiRssi, batteryMv, ...otherKeys } = sample
    return {
        sampleDate: new Date(sample.sampleEpoch * 1000),
        sampleEpoch,
        ph,
        orpMv,
        waterTempC,
        wifiRssi,
        batteryMv,
        otherKeys
    }
}

function _connect() {
    if (client) client.end(true)
    if (!liveInfo.value) throw new Error('must fetch live info first')

    client = mqtt.connect(liveInfo.value.liveStreamUrl, {
        transformWsUrl: (url: string, _options: unknown, _client: unknown) => {
            // This is invoked when a connection drops and the client attempts to reconnect.
            // This function should update the authorization from the API server to get a fresh token.
            // TODO: fetch a token
            return url
        }
    })

    client.on('connect', () => {
        const topic = liveInfo.value?.topic
        if (!topic) throw new Error('must fetch live info topic first')

        client?.subscribe(topic, (err: unknown) => {
            if (err) {
                const message = err instanceof Error ? err.message : String(err)
                console.error('Subscribe error', err)
                error.value = 'Subscribe error: ' + message
                state.value = 'failed'
            } else {
                state.value = 'connected'
            }
        })
    })

    client.on('disconnect', () => {
        state.value = 'connecting'
    })

    client.on('close', () => {
        state.value = 'connecting'
    })

    client.on('error', (err: unknown) => {
        const message = err instanceof Error ? err.message : String(err)
        state.value = 'failed'
        error.value = message
    })

    client.on('message', (_topic: string, message: { toString: () => string }) => {
        try {
            const rawSample = JSON.parse(message.toString())

            const samplesArr = samples.value

            samplesArr.unshift(_toDisplayableSample(rawSample as MonitorPreviousSampleV1))
            if (samplesArr.length > 100) samplesArr.pop()

            samples.value = _sort(samples.value)
        } catch (error) {
            console.error(`Error parsing sample: `, message.toString())
        }
    })
}

onUnmounted(() => {
    client?.end()
})

onMounted(async () => {
    breadCrumbsStore.$patch({
        items: [
            {
                text: `Monitors`
            },
            {
                text: props.monitorId,
                to: {
                    name: 'MonitorDetails',
                    params: {
                        monitorId: props.monitorId
                    }
                },
                exact: true
            },
            {
                text: `Live Stream`
            }
        ]
    })
    await _fetchLiveStreamInfo()
    _connect()
})
</script>
