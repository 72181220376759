<template>
    <v-dialog :modelValue="modelValue" @update:modelValue="syncVModel" :min-width="800" :width="1000">
        <v-card>
            <v-card-title>
                <h3 class="d-flex align-center">Chem Check Info <v-btn icon="mdi-content-copy" @click="copy" size="small" class="ml-4"></v-btn></h3>
            </v-card-title>
            <v-card-text>
                <pre>{{ vesselChemCheckInfo }}</pre>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="gray-darken-1" @click="syncVModel(false)" size="large">Close</v-btn>
            </v-card-actions>
            <v-overlay contained :model-value="loadingChemCheckInfo" class="align-center justify-center">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
    <v-snackbar v-model="showCopiedChemCheckInfoSnackBar" multi-line color="success" :timeout="3000">Copied to Clipboard</v-snackbar>
</template>

<script lang="ts" setup>
import { useApi } from '@/api'
import { ref, watch } from 'vue'
import { copyToClipboard, handleApiError } from '@/lib/utils'
import { type VesselChemCheckInfoV1 } from '@general-galactic/crystal-api-client'

const loadingChemCheckInfo = ref(false)
const vesselChemCheckInfo = ref<VesselChemCheckInfoV1 | undefined>()
const showCopiedChemCheckInfoSnackBar = ref(false)

const props = defineProps({
    monitorId: {
        type: String
    },
    vesselId: {
        type: Number
    },
    modelValue: {
        type: Boolean
    }
})

const emit = defineEmits<{
    (event: 'update:modelValue', value: boolean): void
}>()

const syncVModel = (value: boolean) => {
    emit('update:modelValue', value)
}

watch(
    () => props.modelValue,
    () => {
        if (props.modelValue === true) fetchChemCheckInfo()
    }
)

const copy = () => {
    if (vesselChemCheckInfo.value === undefined) return
    copyToClipboard(JSON.stringify(vesselChemCheckInfo.value, null, '\t'))
    showCopiedChemCheckInfoSnackBar.value = true
}

const fetchChemCheckInfo = async () => {
    if (props.monitorId !== undefined) return fetchMonitorChemCheckInfo()
    if (props.vesselId !== undefined) return fetchVesselChemCheckInfo()
}

const fetchVesselChemCheckInfo = async () => {
    const vesselId = props.vesselId
    if (vesselId === undefined) return

    handleApiError(
        async () => {
            loadingChemCheckInfo.value = true
            vesselChemCheckInfo.value = await useApi().getVesselChemCheckInfoV1({ vesselId })
        },
        async () => {
            loadingChemCheckInfo.value = false
        }
    )
}

const fetchMonitorChemCheckInfo = async () => {
    const monitorId = props.monitorId
    if (monitorId === undefined) return

    handleApiError(
        async () => {
            loadingChemCheckInfo.value = true
            vesselChemCheckInfo.value = await useApi().getMonitorChemCheckInfoV1({ monitorId })
        },
        async () => {
            loadingChemCheckInfo.value = false
        }
    )
}
</script>

<style lang="scss" scoped></style>
