<template>
    <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
    <v-table v-else class="chemicals">
        <thead>
            <tr>
                <th>Slot</th>
                <th>Chemical</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="chemicalSlot of chemicalSlots" :key="chemicalSlot.chemicalUse">
                <td>
                    <strong>{{ chemicalSlot.shortName }}</strong>
                </td>
                <td>
                    <div v-if="chemicalSlot.chemical" class="chemical">
                        <span class="chemicalSource" v-if="chemicalSlot.chemical.id.accountChemicalId !== undefined"
                            >Account Chemical #{{ chemicalSlot.chemical.id.accountChemicalId }}</span
                        >
                        <span class="chemicalSource" v-else-if="chemicalSlot.chemical.id.systemChemicalId !== undefined"
                            >System Chemical #{{ chemicalSlot.chemical.id.systemChemicalId }}</span
                        >
                        <span class="chemicalSource" v-else-if="chemicalSlot.chemical.id.protocolChemicalId !== undefined"
                            >Protocol Chemical #{{ chemicalSlot.chemical.id.protocolChemicalId }}</span
                        >
                        <br />
                        <strong>{{ chemicalSlot.chemical.name }}</strong
                        ><br />
                        {{ formatIngredientName(chemicalSlot.chemical) }}
                        <span v-if="chemicalSlot.chemical.chemicalPercentage !== undefined">{{ chemicalSlot.chemical.chemicalPercentage }}%</span> &#x2022;
                        {{ formatBarcode(chemicalSlot.chemical) }}<br />
                        <v-img :src="chemicalSlot.chemical.imageUrl" class="chemicalImage" />
                    </div>
                </td>
                <td>
                    <div v-if="chemicalSlot.chemical" class="text-right">
                        <div class="d-flex flex-row justify-end align-center">
                            <v-btn
                                v-if="chemicalSlot.chemical"
                                @click="doseChemical(chemicalSlot.chemical)"
                                class="mr-3"
                                size="small"
                                color="secondary"
                                variant="flat"
                                ><v-icon icon="mdi-beaker-outline" slot="icon"></v-icon> Dose</v-btn
                            >
                            <v-btn
                                v-if="!chemicalSlot.readonly"
                                @click="confirmRemoveChemicalFromSlot(chemicalSlot)"
                                title="Remove Chemical"
                                size="small"
                                icon="mdi-minus"
                                color="error"
                                variant="outlined"
                            />
                        </div>
                    </div>
                    <div v-else class="d-flex flex-row align-center justify-end">
                        <v-btn
                            @click="showChooseChemicalModal(chemicalSlot)"
                            title="Choose Chemical"
                            size="small"
                            icon="mdi-plus"
                            color="primary"
                            variant="outlined"
                        />
                    </div>
                </td>
            </tr>
        </tbody>
    </v-table>
    <dose-chemical-modal v-model="showDoseChemicalModal" :chemical="selectedChemical!" :vessel-volume="vesselVolume" :account-id="accountId" />
    <choose-chemical-modal
        v-if="chooseChemicalUse"
        v-model="chooseChemicalModalVisible"
        :chemical-use="chooseChemicalUse"
        :account-id="accountId"
        :vessel-id="vesselId"
        :chemical-use-short-name="chooseChemicalSlotShortName"
        @did-choose-chemical="didChooseChemical"
    />
    <v-dialog v-model="showConfirmRemoveChemicalFromSlotModal" max-width="800">
        <v-card>
            <v-card-title>Are you sure you want to remove this chemical?</v-card-title>
            <v-card-actions>
                <v-btn color="red-darken-1" :loading="loading" @click="removeChemicalFromSlot">YES, REMOVE IT</v-btn>
                <v-btn color="gray-darken-1" @click=";(showConfirmRemoveChemicalFromSlotModal = false), (slotToRemoveChemicalFrom = undefined)"
                    >NO, CANCEL</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
import { useApi } from '@/api'
import { handleApiError } from '@/lib/utils'
import type { ChemicalUsesV1, ChemicalV2, VesselChemicalSlotV2 } from '@general-galactic/crystal-api-client'
import { onMounted } from 'vue'
import { ref } from 'vue'
import DoseChemicalModal from './DoseChemicalModal.vue'
import ChooseChemicalModal from './ChooseChemicalModal.vue'
import { formatBarcode, formatIngredientName } from '@/lib/chemicals'

const props = defineProps({
    accountId: {
        type: Number,
        required: true
    },
    vesselId: {
        type: Number,
        required: true
    },
    vesselVolume: {
        type: Number,
        required: false
    }
})

const chemicalSlots = ref<VesselChemicalSlotV2[] | undefined>()
const loading = ref(false)
const showDoseChemicalModal = ref(false)
const selectedChemical = ref<ChemicalV2 | undefined>()

const chooseChemicalModalVisible = ref(false)
const chooseChemicalUse = ref<ChemicalUsesV1 | undefined>()
const chooseChemicalSlotShortName = ref<string | undefined>()

const showChooseChemicalModal = (slot: VesselChemicalSlotV2) => {
    chooseChemicalUse.value = slot.chemicalUse
    chooseChemicalSlotShortName.value = slot.shortName
    chooseChemicalModalVisible.value = true
}

const didChooseChemical = (chemicalUse: ChemicalUsesV1, chemical: ChemicalV2) => {
    handleApiError(async () => {
        const result = await useApi().putVesselChemicalV2({
            accountId: props.accountId,
            vesselId: props.vesselId,
            chemicalUse: chemicalUse,
            putVesselChemicalV2: {
                systemChemicalId: chemical.id.systemChemicalId,
                accountChemicalId: chemical.id.accountChemicalId
            }
        })
        console.log('PUT ACCOUNT CHEM', result)
        chooseChemicalModalVisible.value = false
        fetchVesselChemicalSlots()
    })
}

const fetchVesselChemicalSlots = async () => {
    try {
        loading.value = true
        const result = await useApi().getVesselChemicalSlotsV2({ accountId: props.accountId, vesselId: props.vesselId })
        chemicalSlots.value = result.chemicalSlots
    } finally {
        loading.value = false
    }
}

onMounted(() => {
    fetchVesselChemicalSlots()
})

const doseChemical = (chemical: ChemicalV2) => {
    selectedChemical.value = chemical
    showDoseChemicalModal.value = true
}

const showConfirmRemoveChemicalFromSlotModal = ref(false)
const slotToRemoveChemicalFrom = ref<VesselChemicalSlotV2 | undefined>()

const confirmRemoveChemicalFromSlot = async (slot: VesselChemicalSlotV2) => {
    slotToRemoveChemicalFrom.value = slot
    showConfirmRemoveChemicalFromSlotModal.value = true
}

const removeChemicalFromSlot = async () => {
    loading.value = true

    await handleApiError(
        async () => {
            if (slotToRemoveChemicalFrom.value === undefined) return

            await useApi().deleteVesselChemicalV1({
                accountId: props.accountId,
                vesselId: props.vesselId,
                chemicalUse: slotToRemoveChemicalFrom.value.chemicalUse
            })

            fetchVesselChemicalSlots()
        },
        async () => {
            loading.value = false
            showConfirmRemoveChemicalFromSlotModal.value = false
            slotToRemoveChemicalFrom.value = undefined
        }
    )
}
</script>

<style lang="scss" scoped>
.chemical {
    padding: 10px;

    .chemicalSource {
        text-transform: uppercase;
        font-size: 10px;
        color: #666;
        letter-spacing: 2px;
    }

    .chemicalImage {
        max-width: 80px;
        margin-top: 10px;
    }
}
</style>
