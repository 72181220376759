<template>
    <v-card v-if="vessel" color="#385F73" theme="dark" class="vesselCard" height="200px" min-width="220px" :append-avatar="oemLogo" :title="vessel.name">
        <v-card-subtitle>{{ Number(vessel.volumeGallons).toLocaleString() }} gallon {{ vessel.type }}</v-card-subtitle>
        <v-card-subtitle>Uses '{{ vessel.protocolName }}' protocol</v-card-subtitle>
        <v-card-subtitle v-if="vessel.status?.dates?.lastActionAlertDate">
            <time-ago prepend="Last alerted " :date="vessel.status.dates.lastActionAlertDate" />
        </v-card-subtitle>
        <v-card-subtitle v-if="vessel?.status?.dates?.lastRefillDate">
            <time-ago prepend="Last refilled " :date="vessel.status.dates.lastRefillDate" />
        </v-card-subtitle>
        <v-card-subtitle v-if="vessel.oemId">Made by {{ vessel.oemId }}</v-card-subtitle>
        <v-btn
            icon="mdi-pencil-circle"
            class="vesselCardAction"
            @click.stop="editVessel"
            title="Edit Vessel"
            variant="text"
            density="compact"
            size="x-large"
        ></v-btn>
    </v-card>
</template>

<script lang="ts" setup>
import { useVesselStore } from '@/stores/vessel'
import { computed } from 'vue'
import TimeAgo from '../TimeAgo.vue'

const vesselStore = useVesselStore()

const vessel = computed(() => {
    return vesselStore.vessel
})

const oemLogo = computed(() => {
    switch (vessel.value?.oemId) {
        case 'bullfrog':
            return '/oems/bullfrog.svg'
    }

    return undefined
})

const emits = defineEmits(['editVessel'])

const editVessel = () => {
    emits('editVessel')
}
</script>

<style lang="scss" scoped></style>
