<template>
    <v-dialog :modelValue="modelValue" @update:modelValue="syncVModel">
        <v-card :min-width="800">
            <v-card-title>
                <h3>Edit Vessel For Testing</h3>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-table>
                            <tbody>
                                <tr class="editableValue">
                                    <th>
                                        <h3>Vessel Created Date</h3>
                                        <p class="note">Affects initially suppressed timed actions</p>
                                    </th>
                                    <td>
                                        <date-time-input
                                            v-model="updatedVesselCreatedDate"
                                            :allow-future-date="false"
                                            @validation-error="showError"
                                            :actions="[{ type: 'subtract', duration: { days: 8 } }]"
                                        />
                                        <div v-if="vesselCreatedDateUpdated && vesselCreatedDate" class="updatedMessage">
                                            Was {{ formatDate(vesselCreatedDate) }}
                                        </div>
                                    </td>
                                    <td></td>
                                </tr>

                                <tr v-if="performsRefills" class="editableValue">
                                    <th>
                                        <h3>Vessel Refill Date</h3>
                                        <p class="note">Affects refill actions and some @ease actions</p>
                                    </th>
                                    <td>
                                        <date-time-input v-model="updatedVesselRefillDate" :allow-future-date="false" @validation-error="showError" />
                                        <div v-if="vesselRefillDateUpdated && vesselRefillDate" class="updatedMessage">
                                            Was {{ formatDate(vesselRefillDate) }}
                                        </div>
                                    </td>
                                    <td>
                                        <v-btn @click="updatedVesselRefillDate = undefined" color="red-darken-2" size="small">CLEAR</v-btn>
                                    </td>
                                </tr>

                                <tr
                                    v-for="actionType of actionTypesToShowInUI"
                                    :key="actionType"
                                    :class="{ modified: modifiedActionCompletionDates[actionType] !== undefined }"
                                >
                                    <th>
                                        <h3>{{ actionType }}</h3>
                                    </th>

                                    <td>
                                        <div class="d-flex flex-row align-center my-2">
                                            <date-time-input
                                                v-model="updatedActionCompletionDates[actionType]"
                                                :allow-future-date="false"
                                                @validation-error="showError"
                                            />
                                        </div>
                                        <div v-if="modifiedActionCompletionDates[actionType] !== undefined" class="updatedMessage">
                                            <span v-if="originalActionCompletionDates && originalActionCompletionDates[actionType] !== undefined">
                                                Was {{ originalCompletionDateStringForActionType(actionType) }}
                                            </span>
                                            <span v-else> Was Not Set </span>
                                        </div>
                                    </td>
                                    <td>
                                        <v-btn
                                            v-if="updatedActionCompletionDates[actionType] !== null && updatedActionCompletionDates[actionType] !== undefined"
                                            @click="clearLastCompletedDateForActionType(actionType)"
                                            color="red-darken-2"
                                            size="small"
                                            >DELETE</v-btn
                                        >
                                        <v-btn
                                            v-if="addedActionTypes.indexOf(actionType) !== -1"
                                            @click="removeAddedItem(actionType)"
                                            color="red-darken-2"
                                            size="small"
                                            class="ml-2"
                                            >REMOVE</v-btn
                                        >
                                        <v-btn
                                            v-if="modifiedActionCompletionDates[actionType] !== undefined"
                                            @click="resetCompletionDate(actionType)"
                                            color="blue-darken-2"
                                            size="small"
                                            class="ml-2"
                                            >RESET</v-btn
                                        >
                                    </td>
                                </tr>
                            </tbody>
                        </v-table>

                        <v-menu>
                            <template v-slot:activator="{ props }">
                                <v-btn v-bind="props" icon="mdi-plus"></v-btn>
                            </template>
                            <v-list>
                                <v-list-item
                                    v-for="actionType in actionTypesNotShownInUI"
                                    :key="actionType"
                                    :value="actionType"
                                    @click="addActionType(actionType)"
                                >
                                    <v-list-item-title>{{ actionType }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-col>
                </v-row>
                <v-overlay :model-value="loading" class="align-center justify-center">
                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                </v-overlay>

                <v-snackbar v-model="showErrorSnackBar" multi-line contained :timeout="4000" color="red-darken-2">
                    {{ snackBarText }}
                    <template v-slot:actions>
                        <v-btn color="white" variant="text" @click="showErrorSnackBar = false"> OK </v-btn>
                    </template>
                </v-snackbar>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue-darken-1" @click="syncVModel(false)">Close</v-btn>
                <v-btn color="red-darken-1" @click="saveChanges" :loading="loading" :disabled="!dataUpdated">Save Changes</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { useVesselStore } from '@/stores/vessel'
import { ActionType, VesselTypes, type AccountVesselV1, type PutVesselTestingUpdatesV1 } from '@general-galactic/crystal-api-client'
import { formatDate, handleApiError } from '@/lib/utils'
import { useApi } from '@/api'
import DateTimeInput from '../DateTimeInput.vue'
import { isSameSecond } from 'date-fns'

const vesselStore = useVesselStore()

const props = defineProps({
    accountId: {
        type: Number,
        required: true
    },
    vesselId: {
        type: Number,
        required: true
    },
    modelValue: {
        type: Boolean
    }
})

const emit = defineEmits<{
    (event: 'update:modelValue', value: boolean): void
    (event: 'vesselUpdated'): void
}>()

const loading = ref(false)
const showErrorSnackBar = ref(false)
const snackBarText = ref<string | undefined>()

const dataUpdated = computed<boolean>(() => {
    return vesselCreatedDateUpdated.value || vesselRefillDateUpdated.value || actionCompletionDatesUpdated.value
})

onMounted(async () => {
    await vesselStore.loadVessel(props.accountId, props.vesselId)
})

const vessel = computed<AccountVesselV1 | undefined | null>(() => {
    return vesselStore.vessel
})

const originalCompletionDateStringForActionType = (actionType: ActionType): string => {
    const date = originalActionCompletionDates.value && originalActionCompletionDates.value[actionType]
    if (date === undefined) return ''
    return formatDate(date)
}

// Actions type from the server that have a completion date - show in UI automatically
const actionTypesThatHaveACompletedDate = computed(() => {
    const dates = originalActionCompletionDates.value
    if (dates === undefined) return []

    const types = new Set<ActionType>()
    for (const actionType of Object.values(ActionType)) {
        if (dates[actionType] !== undefined) {
            types.add(actionType)
        }
    }
    return Array.from(types)
})

// Action types that the user has added using the plus button
const addedActionTypes = ref<ActionType[]>([])

// Subset of all action types that should be displayed in the table
// Comprised of action types that have a completion date and the actions types
// the user has added with the plus button
const actionTypesToShowInUI = computed(() => {
    const types = new Set<ActionType>(actionTypesThatHaveACompletedDate.value)

    for (const type of addedActionTypes.value) {
        types.add(type)
    }

    return Array.from(types)
})

// Remaining subset of action types that are not displayed in the table
// AllActionTypes - actionTypesToShowInUI
const actionTypesNotShownInUI = computed(() => {
    const typesShown = actionTypesToShowInUI.value
    const typesNotShown = new Set<ActionType>()
    for (const type of Object.values(ActionType)) {
        if (typesShown.indexOf(type) === -1) {
            typesNotShown.add(type)
        }
    }
    return Array.from(typesNotShown).sort()
})

// Model that is hooked to the UI input fields
const updatedActionCompletionDates = reactive<{ [property in ActionType]?: Date }>({})

// Current server values - not modified until a reload
const originalActionCompletionDates = computed(() => {
    return vessel.value?.actionStatuses.reduce((collector, status) => {
        collector[status.actionType] = status.lastCompletedDate
        return collector
    }, {} as Record<ActionType, Date | undefined>)
})

// Subset of action types that the user has modified in the UI
const modifiedActionCompletionDates = computed(() => {
    const originalDates = originalActionCompletionDates.value
    if (originalDates === undefined) return {}

    const modifiedValues: { [property in ActionType]?: Date | null } = {}

    for (const actionType of Object.values(ActionType)) {
        const oldValue = originalDates[actionType]
        const newValue = updatedActionCompletionDates[actionType]

        const modified = dateModified(oldValue, newValue)
        if (modified) {
            if (newValue === undefined) {
                modifiedValues[actionType] = null
            } else {
                modifiedValues[actionType] = newValue
            }
        }
    }

    return modifiedValues
})

const dateModified = (originalDate: Date | undefined, updatedDate: Date | undefined) => {
    return !datesCloseEnough(originalDate, updatedDate)
}

const datesCloseEnough = (originalDate: Date | undefined, updatedDate: Date | undefined) => {
    if (originalDate === undefined) return updatedDate === undefined
    if (updatedDate === undefined) return originalDate === undefined
    // The date input control ditches milliseconds. If we use isEqual here clearing and resetting a value leaves a difference in the UI.
    return isSameSecond(originalDate, updatedDate)
}

// Boolean indicating if the user has modified any of the action completion dates
const actionCompletionDatesUpdated = computed<boolean>(() => {
    if (modifiedActionCompletionDates.value === undefined) return false
    return Object.keys(modifiedActionCompletionDates.value).length > 0
})

// Clears a date (delete completion)
const clearLastCompletedDateForActionType = (actionType: ActionType) => {
    updatedActionCompletionDates[actionType] = undefined
}

// Removes an action type that was added by the plus button
const removeAddedItem = (actionType: ActionType) => {
    const index = addedActionTypes.value.indexOf(actionType)
    if (index > -1) addedActionTypes.value.splice(index, 1)
}

const addActionType = (actionType: ActionType) => {
    updatedActionCompletionDates[actionType] = new Date()
    addedActionTypes.value.push(actionType)
}

// Restores a completion date in the UI to the original server value
const resetCompletionDate = (actionType: ActionType) => {
    const originalValue = originalActionCompletionDates.value && originalActionCompletionDates.value[actionType]
    updatedActionCompletionDates[actionType] = originalValue
}

watch(originalActionCompletionDates, (originalActionCompletionDates) => {
    if (originalActionCompletionDates === undefined) {
        for (const key of Object.values(ActionType)) {
            updatedActionCompletionDates[key] = undefined
        }
        return
    }

    for (const key of Object.values(ActionType)) {
        updatedActionCompletionDates[key] = originalActionCompletionDates[key]
    }
})

const performsRefills = computed(() => {
    return vessel.value?.type === VesselTypes.Hottub || vessel.value?.type === VesselTypes.Swimspa
})

const vesselCreatedDate = computed(() => {
    return vessel.value?.createdAt
})

const updatedVesselCreatedDate = ref<Date | undefined | null>()

const vesselCreatedDateUpdated = computed<boolean>(() => {
    return vesselCreatedDate.value !== updatedVesselCreatedDate.value
})

const vesselRefillDate = computed(() => {
    return vessel.value?.status?.dates?.lastRefillDate
})

const updatedVesselRefillDate = ref<Date | undefined | null>()

const vesselRefillDateUpdated = computed<boolean>(() => {
    return vesselRefillDate.value !== updatedVesselRefillDate.value
})

watch(vessel, () => {
    updatedVesselCreatedDate.value = vesselCreatedDate.value
    updatedVesselRefillDate.value = vesselRefillDate.value
})

const syncVModel = (value: boolean) => {
    emit('update:modelValue', value)
}

const showError = (message: string) => {
    snackBarText.value = message
    showErrorSnackBar.value = true
}

function _toServerValue(value: Date | undefined | null): string | undefined {
    if (value === undefined) return 'null'
    if (value === null) return 'null'
    return value.toISOString()
}

const saveChanges = async () => {
    const updates: PutVesselTestingUpdatesV1 = {}

    if (updatedVesselCreatedDate.value !== undefined && updatedVesselCreatedDate.value !== null && vesselCreatedDateUpdated.value) {
        updates.vesselCreatedDate = updatedVesselCreatedDate.value
    }

    if (vesselRefillDateUpdated.value) {
        updates.lastRefillDate = _toServerValue(updatedVesselRefillDate.value)
    }

    if (Object.keys(modifiedActionCompletionDates.value).length > 0) {
        const serverValues: { [actionType in ActionType]?: string } = {}
        for (const actionType of Object.keys(modifiedActionCompletionDates.value) as ActionType[]) {
            const date = modifiedActionCompletionDates.value[actionType]
            if (date === undefined) continue
            serverValues[actionType] = _toServerValue(date)
        }
        updates.actionCompletionDates = serverValues
    }

    handleApiError(
        async () => {
            loading.value = true
            const result = await useApi().putVesselTestingUpdatesV1({
                accountId: props.accountId,
                vesselId: props.vesselId,
                putVesselTestingUpdatesV1: updates
            })
            console.log('Result', result)
            emit('vesselUpdated')
            syncVModel(false)
        },
        async () => {
            loading.value = false
        }
    )
}
</script>

<style lang="scss" scoped>
.editableValue {
    .note {
        font-weight: 300;
    }
    .updatedMessage {
        padding: 4px 0;
        color: darkred;
    }
}

.modified {
    background-color: rgba(200, 0, 0, 0.1);
}
</style>
