<template>
    <v-dialog :modelValue="modelValue" @update:modelValue="syncVModel">
        <v-card :min-width="800">
            <v-card-title>
                <h3>Add Vessel</h3>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="v$.vesselName.$model"
                                label="Vessel Name"
                                required
                                :error-messages="vuelidateErrors(v$.vesselName)"
                                @input="v$.vesselName.$touch"
                                @blur="v$.vesselName.$touch"
                            />
                        </v-col>
                        <v-col>
                            <vessel-oem-select v-model="state.vesselOem"></vessel-oem-select>
                        </v-col>
                        <v-col>
                            <vessel-type-select v-model="v$.vesselType.$model" :error-messages="vuelidateErrors(v$.vesselType)" @blur="v$.vesselType.$touch" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="v$.vesselVolumeGallons.$model"
                                label="Volume"
                                required
                                type="number"
                                suffix="gal."
                                :error-messages="vuelidateErrors(v$.vesselVolumeGallons)"
                                @input="v$.vesselVolumeGallons.$touch"
                                @blur="v$.vesselVolumeGallons.$touch"
                            />
                        </v-col>
                        <v-col>
                            <vessel-protocol-select
                                v-model="v$.vesselProtocol.$model"
                                :vessel-type="v$.vesselType.$model ?? VesselTypes.Pool"
                                :vessel-oem="state.vesselOem"
                                :error-messages="vuelidateErrors(v$.vesselProtocol)"
                                @blur="v$.vesselProtocol.$touch"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="v$.vesselLocation.line1.$model"
                                label="Address Line 1"
                                required
                                :error-messages="vuelidateErrors(v$.vesselLocation.line1)"
                                @input="v$.vesselLocation.line1.$touch"
                                @blur="v$.vesselLocation.line1.$touch"
                            />
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="v$.vesselLocation.line2.$model"
                                label="Address Line 2"
                                :error-messages="vuelidateErrors(v$.vesselLocation.line2)"
                                @input="v$.vesselLocation.line2.$touch"
                                @blur="v$.vesselLocation.line2.$touch"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="v$.vesselLocation.locality.$model"
                                label="City"
                                required
                                :error-messages="vuelidateErrors(v$.vesselLocation.locality)"
                                @input="v$.vesselLocation.locality.$touch"
                                @blur="v$.vesselLocation.locality.$touch"
                            />
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="v$.vesselLocation.administrativeAreaLevel1.$model"
                                label="State"
                                :error-messages="vuelidateErrors(v$.vesselLocation.administrativeAreaLevel1)"
                                @input="v$.vesselLocation.administrativeAreaLevel1.$touch"
                                @blur="v$.vesselLocation.administrativeAreaLevel1.$touch"
                            />
                        </v-col>
                        <v-col>
                            <v-text-field
                                v-model="v$.vesselLocation.postalCode.$model"
                                label="Postal Code"
                                :error-messages="vuelidateErrors(v$.vesselLocation.postalCode)"
                                @input="v$.vesselLocation.postalCode.$touch"
                                @blur="v$.vesselLocation.postalCode.$touch"
                            />
                        </v-col>
                    </v-row>
                </v-container>
                <v-overlay :model-value="addingVessel" class="align-center justify-center">
                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                </v-overlay>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue-darken-1" @click="syncVModel(false)">Close</v-btn>
                <v-btn color="blue-darken-1" @click="submit" :disabled="v$.$invalid">Add Vessel</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script lang="ts" setup>
import { useApi } from '@/api'
import { reactive } from 'vue'
import { ref } from 'vue'
import VesselTypeSelect from './VesselTypeSelect.vue'
import VesselProtocolSelect from './VesselProtocolSelect.vue'
import VesselOemSelect from './VesselOemSelect.vue'
import { required, minValue, maxValue, maxLength } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { handleApiError, vuelidateErrors } from '@/lib/utils'
import { type ProtocolV2, type AddAccountVesselLocationV1, VesselTypes } from '@general-galactic/crystal-api-client'

const props = defineProps({
    accountId: {
        type: Number,
        required: true
    },
    modelValue: {
        type: Boolean
    }
})

const emit = defineEmits<{
    (event: 'vesselAdded'): void
    (event: 'update:modelValue', value: boolean): void
}>()

const addingVessel = ref(false)

const state = reactive<{
    vesselName: string | undefined
    vesselOem: string | undefined
    vesselType: VesselTypes | undefined
    vesselVolumeGallons: number | undefined
    vesselProtocol: ProtocolV2 | undefined
    vesselLocation: Partial<AddAccountVesselLocationV1>
}>({
    vesselName: undefined,
    vesselOem: undefined,
    vesselType: undefined,
    vesselVolumeGallons: undefined,
    vesselProtocol: undefined,
    vesselLocation: {
        line1: undefined,
        line2: undefined,
        locality: undefined,
        administrativeAreaLevel1: undefined,
        postalCode: undefined
    }
})

const rules = {
    vesselName: { required, maxLength: maxLength(40) },
    vesselType: { required },
    vesselVolumeGallons: { required, minValue: minValue(160), maxValue: maxValue(50000) },
    vesselProtocol: { required },
    vesselLocation: {
        line1: { required, maxLength: maxLength(255) },
        line2: { maxLength: maxLength(255) },
        locality: { required, maxLength: maxLength(255) },
        administrativeAreaLevel1: { required, maxLength: maxLength(2) },
        postalCode: { required, maxLength: maxLength(9) }
    }
}

const v$ = useVuelidate(rules, state)

const syncVModel = (value: boolean) => {
    emit('update:modelValue', value)
}

const submit = async () => {
    const isFormCorrect = await v$.value.$validate()
    if (!isFormCorrect) return

    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    await handleApiError(
        async () => {
            addingVessel.value = true
            const result = await useApi().addAccountVesselV2({
                accountId: props.accountId,
                addAccountVesselV1: {
                    name: state.vesselName!,
                    oemId: state.vesselOem,
                    type: state.vesselType!,
                    volumeGallons: state.vesselVolumeGallons!,
                    protocolId: state.vesselProtocol!.id,
                    location: {
                        line1: state.vesselLocation.line1!,
                        line2: state.vesselLocation.line2,
                        locality: state.vesselLocation.locality!,
                        administrativeAreaLevel1: state.vesselLocation.administrativeAreaLevel1!,
                        postalCode: state.vesselLocation.postalCode!
                    }
                }
            })
            console.log('added vessel', result)
            emit('vesselAdded')
            syncVModel(false)
        },
        async () => {
            addingVessel.value = false
        }
    )
    /* eslint-enable @typescript-eslint/no-non-null-assertion */
}
</script>

<style lang="scss" scoped></style>
