import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import HomeView from '../views/HomeView.vue'
import AccountDetails from '../views/AccountDetails.vue'
import VesselDetails from '../views/VesselDetails.vue'
import MonitorDetails from '../views/MonitorDetails.vue'
import MonitorLiveStream from '../views/MonitorLiveStream.vue'
import VesselActionHistory from '../views/VesselActionHistory.vue'
import MonitorCalibrations from '../views/monitors/MonitorCalibrations.vue'
import MonitorJobExecutionDetails from '../views/monitors/MonitorJobExecutionDetails.vue'
import WaterReportHistory from '../views/waterReports/WaterReportHistory.vue'
import VesselChemCheckHistory from '../views/VesselChemCheckHistory.vue'
import SensorDetails from '../views/sensors/SensorDetails.vue'
import VesselStatusUpdateLambdaLogs from '../views/engineering/VesselStatusUpdateLambdaLogs.vue'
import ProtocolInfo from '../views/engineering/ProtocolInfo.vue'
import { createAuthGuard } from '@auth0/auth0-vue'

const authGuard = createAuthGuard()

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/login',
            name: 'login',
            component: LoginView
        },
        {
            path: '/',
            name: 'home',
            component: HomeView,
            beforeEnter: authGuard
        },
        {
            path: '/accounts/:accountId',
            name: 'AccountDetails',
            component: AccountDetails,
            beforeEnter: authGuard,
            props: (route) => {
                if (Array.isArray(route.params.accountId)) throw Error(`accountId cannot be an array`)
                const accountId = parseInt(route.params.accountId, 10)
                return { accountId }
            }
        },
        {
            path: '/accounts/:accountId/vessels/:vesselId',
            name: 'VesselDetails',
            component: VesselDetails,
            beforeEnter: authGuard,
            props: (route) => {
                if (Array.isArray(route.params.accountId)) throw Error(`accountId cannot be an array`)
                if (Array.isArray(route.params.vesselId)) throw Error(`vesselId cannot be an array`)
                const accountId = parseInt(route.params.accountId, 10)
                const vesselId = parseInt(route.params.vesselId, 10)
                return { accountId, vesselId }
            }
        },
        {
            path: '/accounts/:accountId/vessels/:vesselId/actions',
            name: 'VesselActionHistory',
            component: VesselActionHistory,
            beforeEnter: authGuard,
            props: (route) => {
                if (Array.isArray(route.params.accountId)) throw Error(`accountId cannot be an array`)
                if (Array.isArray(route.params.vesselId)) throw Error(`vesselId cannot be an array`)
                const accountId = parseInt(route.params.accountId, 10)
                const vesselId = parseInt(route.params.vesselId, 10)
                return { accountId, vesselId }
            }
        },
        {
            path: '/accounts/:accountId/vessels/:vesselId/waterReports',
            name: 'WaterReportHistory',
            component: WaterReportHistory,
            beforeEnter: authGuard,
            props: (route) => {
                if (Array.isArray(route.params.accountId)) throw Error(`accountId cannot be an array`)
                if (Array.isArray(route.params.vesselId)) throw Error(`vesselId cannot be an array`)
                const accountId = parseInt(route.params.accountId, 10)
                const vesselId = parseInt(route.params.vesselId, 10)
                return { accountId, vesselId }
            }
        },
        {
            path: '/accounts/:accountId/vessels/:vesselId/chemCheck/history',
            name: 'VesselChemCheckHistory',
            component: VesselChemCheckHistory,
            beforeEnter: authGuard,
            props: (route) => {
                if (Array.isArray(route.params.accountId)) throw Error(`accountId cannot be an array`)
                if (Array.isArray(route.params.vesselId)) throw Error(`vesselId cannot be an array`)
                const accountId = parseInt(route.params.accountId, 10)
                const vesselId = parseInt(route.params.vesselId, 10)
                return { accountId, vesselId }
            }
        },
        {
            path: '/accounts/:accountId/vessels/:vesselId/protocol/info',
            name: 'ProtocolInfo',
            component: ProtocolInfo,
            beforeEnter: authGuard,
            props: (route) => {
                if (Array.isArray(route.params.accountId)) throw Error(`accountId cannot be an array`)
                if (Array.isArray(route.params.vesselId)) throw Error(`vesselId cannot be an array`)
                const accountId = parseInt(route.params.accountId, 10)
                const vesselId = parseInt(route.params.vesselId, 10)
                return { accountId, vesselId }
            }
        },
        {
            path: '/accounts/:accountId/vessels/:vesselId/chemCheck/history/:requestId/:eventDate/lambdaLogs',
            name: 'VesselStatusUpdateLambdaLogs',
            component: VesselStatusUpdateLambdaLogs,
            beforeEnter: authGuard,
            props: (route) => {
                if (Array.isArray(route.params.accountId)) throw Error(`accountId cannot be an array`)
                if (Array.isArray(route.params.vesselId)) throw Error(`vesselId cannot be an array`)
                const accountId = parseInt(route.params.accountId, 10)
                const vesselId = parseInt(route.params.vesselId, 10)
                const awsRequestId = route.params.requestId
                const eventDate = route.params.eventDate
                return { accountId, vesselId, awsRequestId, eventDate }
            }
        },
        {
            path: '/monitors/:monitorId',
            name: 'MonitorDetails',
            component: MonitorDetails,
            beforeEnter: authGuard,
            props: (route) => {
                if (Array.isArray(route.params.monitorId)) throw Error(`monitorId cannot be an array`)
                const monitorId = route.params.monitorId
                return { monitorId }
            }
        },
        {
            path: '/monitors/:monitorId/jobs/:jobId/executions/:executionNumber',
            name: 'MonitorJobExecutionDetails',
            component: MonitorJobExecutionDetails,
            beforeEnter: authGuard,
            props: (route) => {
                if (Array.isArray(route.params.monitorId)) throw Error(`monitorId cannot be an array`)
                if (Array.isArray(route.params.jobId)) throw Error(`jobId cannot be an array`)
                if (Array.isArray(route.params.executionNumber)) throw Error(`executionNumber cannot be an array`)

                const { monitorId, jobId, executionNumber } = route.params
                return { monitorId, jobId, executionNumber: parseInt(executionNumber, 10) }
            }
        },
        {
            path: '/monitors/:monitorId/live',
            name: 'MonitorLiveStream',
            component: MonitorLiveStream,
            beforeEnter: authGuard,
            props: (route) => {
                if (Array.isArray(route.params.monitorId)) throw Error(`monitorId cannot be an array`)
                const monitorId = route.params.monitorId
                return { monitorId }
            }
        },
        {
            path: '/monitors/:monitorId/calibrations',
            name: 'MonitorCalibrations',
            component: MonitorCalibrations,
            beforeEnter: authGuard,
            props: (route) => {
                if (Array.isArray(route.params.monitorId)) throw Error(`monitorId cannot be an array`)
                const monitorId = route.params.monitorId
                return { monitorId }
            }
        },
        {
            path: '/sensors/:serialNumber',
            name: 'SensorDetails',
            component: SensorDetails,
            beforeEnter: authGuard,
            props: (route) => {
                if (Array.isArray(route.params.monitorId)) throw Error(`serialNumber cannot be an array`)
                const serialNumber = route.params.serialNumber
                return { serialNumber }
            }
        },
        {
            path: '/chemicals/account',
            name: 'AccountChemicals',
            component: () => import('../views/chemicals/AccountChemicals.vue'),
            beforeEnter: authGuard
        },
        {
            path: '/chemicals/account/promote',
            name: 'PromoteAccountChemicals',
            component: () => import('../views/chemicals/PromoteAccountChemicals.vue'),
            beforeEnter: authGuard
        },
        {
            path: '/chemicals/account/:barcode/promote',
            name: 'PromoteAccountChemical',
            component: () => import('../views/chemicals/PromoteAccountChemical.vue'),
            beforeEnter: authGuard,
            props: (route) => {
                if (Array.isArray(route.params.barcode)) throw Error(`barcode cannot be an array`)
                const barcode = route.params.barcode
                return { barcode }
            }
        },
        {
            path: '/chemicals/system',
            name: 'AllSystemChemicals',
            component: () => import('../views/chemicals/AllSystemChemicals.vue'),
            beforeEnter: authGuard
        },
        {
            path: '/chemicals/system/:systemChemicalId',
            name: 'SystemChemicalDetails',
            component: () => import('../views/chemicals/SystemChemicalDetails.vue'),
            beforeEnter: authGuard,
            props: (route) => {
                if (Array.isArray(route.params.systemChemicalId)) throw Error(`systemChemicalId cannot be an array`)
                return { systemChemicalId: parseInt(route.params.systemChemicalId, 10) }
            }
        },
        {
            path: '/engineering/cloudwatch',
            name: 'CloudWatch',
            component: () => import('../views/engineering/CloudWatch.vue'),
            beforeEnter: authGuard
        },
        {
            path: '/engineering/recentteststrips',
            name: 'RecentTestStrips',
            component: () => import('../views/engineering/RecentTestStrips.vue'),
            beforeEnter: authGuard
        },
        {
            path: '/engineering/recentfeedback',
            name: 'RecentFeedback',
            component: () => import('../views/engineering/RecentFeedback.vue'),
            beforeEnter: authGuard
        },
        {
            path: '/engineering/shoveler',
            name: 'VesselStatusUpdateShoveler',
            component: () => import('../views/engineering/VesselStatusUpdateShoveler.vue'),
            beforeEnter: authGuard
        },
        {
            path: '/engineering/returnedMonitors',
            name: 'ReturnedMonitorsScan',
            component: () => import('../views/engineering/ReturnedMonitorsScan.vue'),
            beforeEnter: authGuard
        },
        {
            path: '/engineering/replacementMonitors',
            name: 'ReplacementMonitors',
            component: () => import('../views/engineering/ReplacementMonitors.vue'),
            beforeEnter: authGuard
        },
        {
            path: '/engineering/testStrips/catalog',
            name: 'TestStripCatalog',
            component: () => import('../views/engineering/TestStripCatalog.vue'),
            beforeEnter: authGuard
        },
        {
            path: '/engineering/accounts/:accountId/vessels/:vesselId/compareStatuses',
            name: 'CompareVesselStatuses',
            component: () => import('../views/engineering/CompareVesselStatuses.vue'),
            beforeEnter: authGuard,
            props: (route) => {
                if (Array.isArray(route.params.accountId)) throw Error(`accountId cannot be an array`)
                if (Array.isArray(route.params.vesselId)) throw Error(`vesselId cannot be an array`)
                const accountId = parseInt(route.params.accountId, 10)
                const vesselId = parseInt(route.params.vesselId, 10)
                return { accountId, vesselId }
            }
        }
    ]
})

export default router
