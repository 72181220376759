<template>
    <v-row v-if="jobExecutionDetails">
        <v-col>
            <v-card>
                <v-card-title>Job Details </v-card-title>
                <v-table>
                    <tbody>
                        <tr>
                            <th class="text-left">Job ID</th>
                            <td>
                                {{ jobExecutionDetails.job.jobId }}
                                <v-btn size="small" variant="outlined" color="warning" :href="jobExecutionDetails.job.awsJobUrl" target="_aws" class="awsButton"
                                    >View on AWS</v-btn
                                >
                            </td>
                        </tr>
                        <tr>
                            <th class="text-left">Description</th>
                            <td>{{ jobExecutionDetails.job.description }}</td>
                        </tr>
                        <tr>
                            <th class="text-left">Job Status</th>
                            <td>{{ jobExecutionDetails.job.status }}</td>
                        </tr>
                        <tr>
                            <th class="text-left">Created</th>
                            <td>{{ format(jobExecutionDetails.job.createdAtDate, 'M/d/yy h:mmaaaaa') }}</td>
                        </tr>
                        <tr>
                            <th class="text-left">Last Updated</th>
                            <td>{{ format(jobExecutionDetails.job.lastUpdatedAtDate, 'M/d/yy h:mmaaaaa') }}</td>
                        </tr>
                        <tr v-if="jobExecutionDetails.job.completedAtDate">
                            <th class="text-left">Completed</th>
                            <td>{{ format(jobExecutionDetails.job.completedAtDate, 'M/d/yy h:mmaaaaa') }}</td>
                        </tr>
                        <tr v-if="jobExecutionDetails.job.comment">
                            <th class="text-left">Comment</th>
                            <td>{{ jobExecutionDetails.job.comment }}</td>
                        </tr>
                        <tr>
                            <th class="text-left">Job Target Selection</th>
                            <td>{{ jobExecutionDetails.job.targetSelection }}</td>
                        </tr>
                        <tr>
                            <th class="text-left">Job Targets</th>
                            <td>{{ jobExecutionDetails.job.targets.join(', ') }}</td>
                        </tr>
                        <tr v-if="jobExecutionDetails.job.forceCanceled">
                            <th class="text-left">Force Cancelled</th>
                            <td><v-chip class="ma-2" color="red" text-color="white"> FORCE CANCELLED </v-chip></td>
                        </tr>
                    </tbody>
                </v-table>
            </v-card>
        </v-col>
    </v-row>
    <v-row v-if="jobExecutionDetails">
        <v-col>
            <v-card>
                <v-card-title>{{ monitorId }} Job Execution</v-card-title>
                <v-table>
                    <tbody>
                        <tr>
                            <th class="text-left">Execution Status</th>
                            <td>{{ jobExecutionDetails.execution.status }}</td>
                        </tr>
                        <tr>
                            <th class="text-left">Execution Number</th>
                            <td>{{ jobExecutionDetails.execution.executionNumber }}</td>
                        </tr>
                        <tr>
                            <th class="text-left">Status Details</th>
                            <td>
                                <pre>{{
                                    jobExecutionDetails.execution.statusDetails
                                        ? JSON.stringify(jobExecutionDetails.execution.statusDetails, null, '\t')
                                        : 'None'
                                }}</pre>
                            </td>
                        </tr>
                        <tr v-if="jobExecutionDetails.execution.queuedAtDate">
                            <th class="text-left">Created</th>
                            <td>{{ format(jobExecutionDetails.execution.queuedAtDate, 'M/d/yy h:mmaaaaa') }}</td>
                        </tr>
                        <tr v-if="jobExecutionDetails.execution.startedAtDate">
                            <th class="text-left">Last Updated</th>
                            <td>{{ format(jobExecutionDetails.execution.startedAtDate, 'M/d/yy h:mmaaaaa') }}</td>
                        </tr>
                        <tr v-if="jobExecutionDetails.execution.lastUpdatedAtDate">
                            <th class="text-left">Completed</th>
                            <td>{{ format(jobExecutionDetails.execution.lastUpdatedAtDate, 'M/d/yy h:mmaaaaa') }}</td>
                        </tr>
                        <tr v-if="jobExecutionDetails.execution.forceCanceled">
                            <th class="text-left">Force Cancelled</th>
                            <td><v-chip class="ma-2" color="red" text-color="white"> FORCE CANCELLED </v-chip></td>
                        </tr>
                    </tbody>
                </v-table>
            </v-card>
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useBreadCrumbsStore } from '@/stores/breadCrumbs'
import { useApi } from '@/api'
import { format } from 'date-fns'
import type { MonitorJobExecutionDetailsV1 } from '@general-galactic/crystal-api-client'

const breadCrumbsStore = useBreadCrumbsStore()

const props = defineProps({
    monitorId: {
        type: String,
        required: true
    },
    jobId: {
        type: String,
        required: true
    },
    executionNumber: {
        type: Number,
        required: true
    }
})

onMounted(() => {
    getMonitorJobExecutionDetails()
    breadCrumbsStore.$patch({
        items: [
            {
                text: 'Monitors'
            },
            {
                text: props.monitorId,
                to: {
                    name: 'MonitorDetails',
                    params: {
                        monitorId: props.monitorId
                    }
                }
            },
            {
                text: 'Jobs'
            },
            {
                text: String(props.jobId)
            }
        ]
    })
})

const errorSnackBar = ref(false)
const errorText = ref<string | undefined>()
const loadingJobExecutionDetails = ref(false)
const jobExecutionDetails = ref<MonitorJobExecutionDetailsV1 | undefined>()

const getMonitorJobExecutionDetails = async () => {
    try {
        loadingJobExecutionDetails.value = true
        jobExecutionDetails.value = await useApi().getMonitorJobExecutionV1({
            monitorId: props.monitorId,
            jobId: props.jobId,
            executionNumber: props.executionNumber
        })
    } catch (e) {
        console.error('Error fetching monitor job execution details', e)
        if (e instanceof Error) {
            errorText.value = e.message
            errorSnackBar.value = true
        } else {
            errorText.value = JSON.stringify(e)
            errorSnackBar.value = true
        }
    } finally {
        loadingJobExecutionDetails.value = false
    }
}
</script>

<style scoped>
.awsButton {
    float: right;
}
</style>
