<template>
    <v-dialog :modelValue="modelValue" @update:modelValue="syncVModel">
        <v-card v-if="ticket">
            <v-card-text>
                <v-row>
                    <v-col
                        ><h2 class="d-flex align-center">
                            Ticket #{{ ticket.ticketId }}
                            <v-btn
                                density="compact"
                                icon="mdi-open-in-new"
                                variant="text"
                                class="ml-4"
                                color="primary"
                                :href="ticket.freshdeskUrl"
                                target="_freshdesk"
                                title="View on FreshDesk"
                            /></h2
                    ></v-col>
                    <v-col class="text-right">
                        <strong>{{ format(ticket.createdAt, 'Pp') }}</strong>
                        <v-btn density="compact" icon="mdi-close" variant="text" size="large" class="ml-4" color="black" @click="syncVModel(false)" />
                    </v-col>
                </v-row>
                <v-row v-if="ticket.subject">
                    <v-col>
                        <div class="mb-3">
                            <ticket-status-chip :ticket="ticket" class="mr-2" />
                            <ticket-priority-chip :ticket="ticket" />
                        </div>
                        <strong>Subject</strong><br />
                        {{ ticket.subject }}
                    </v-col>
                </v-row>
                <v-row v-if="ticket.description">
                    <v-col>
                        <strong>Description</strong><br />
                        <div v-if="ticket.description" v-html="ticket.description" class="ticketDescription"></div>
                    </v-col>
                </v-row>
                <v-row v-if="loadingMessages">
                    <v-col>
                        <v-progress-circular indeterminate></v-progress-circular>
                    </v-col>
                </v-row>
                <v-row v-else-if="messages?.length">
                    <v-col sm="12" md="8">
                        <h4 class="mb-3">Conversation</h4>
                        <v-card
                            v-for="message of messages"
                            :key="message.id"
                            class="mb-3"
                            elevation="3"
                            :class="{ outboundMessage: message.incoming === false, inboundMessage: message.incoming === true }"
                        >
                            <v-card-text>
                                <h4 v-if="message.incoming === false">Replied to {{ message.toEmails[0] }} on {{ format(message.createdAt, 'Pp') }}</h4>
                                <h4 v-else>{{ message.fromEmail }} Replied on {{ format(message.createdAt, 'Pp') }}</h4>
                                <div v-html="message.bodyHTML" class="mt-5"></div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script lang="ts" setup>
import type { AccountTicketV1, TicketConversationMessageV1 } from '@general-galactic/crystal-api-client'
import type { PropType } from 'vue'
import { format } from 'date-fns'
import { ref, watch } from 'vue'
import { handleApiError } from '@/lib/utils'
import { useApi } from '@/api'
import TicketPriorityChip from './TicketPriorityChip.vue'
import TicketStatusChip from './TicketStatusChip.vue'

const loadingMessages = ref(false)
const messages = ref<TicketConversationMessageV1[] | undefined>()

const emit = defineEmits<{
    (event: 'update:modelValue', value: boolean): void
}>()

const syncVModel = (value: boolean) => {
    emit('update:modelValue', value)
}

const props = defineProps({
    modelValue: {
        type: Boolean
    },
    ticket: {
        type: Object as PropType<AccountTicketV1>,
        required: false
    }
})

watch([() => props.ticket, () => props.modelValue], () => {
    if (props.ticket !== undefined && props.modelValue === true) {
        getFreshdeskTicketConversation()
    }
})

const getFreshdeskTicketConversation = async () => {
    const ticketId = props.ticket?.ticketId
    if (ticketId === undefined) return

    messages.value = undefined
    handleApiError(
        async () => {
            loadingMessages.value = true
            const response = await useApi().getTicketConversationsV1({
                ticketId
            })
            messages.value = response.messages
        },
        async () => {
            loadingMessages.value = false
        }
    )
}
</script>

<style lang="scss" scoped>
.outboundMessage {
    margin-right: 40px;
    background-color: #f4f4f4;
}

.inboundMessage {
    margin-left: 40px;
}
</style>
