<template>
    <div>
        <v-text-field
            autofocus
            v-model="searchTerm"
            prepend-inner-icon="mdi-magnify"
            :loading="loadingSearchResults"
            label="Search"
            placeholder="Sensor Serial Number"
            clearable
        ></v-text-field>
        <div v-if="loadingSearchResults" class="m-4">
            <v-progress-circular indeterminate size="24"></v-progress-circular>
        </div>
        <v-list
            v-else-if="canUseSearchTerm && searchResults && searchResults.length > 0"
            v-for="searchResult of searchResults"
            :key="searchResult.serialNumber"
            @click="emit('picked:sensor', searchResult)"
            lines="one"
            density="compact"
        >
            <v-list-item :title="searchResult.serialNumber" color="primary" class="sensorResult"></v-list-item>
        </v-list>
        <div v-else-if="canUseSearchTerm">No Results for '{{ searchTerm }}'</div>
    </div>
</template>

<script lang="ts" setup>
import { useApi } from '@/api'
import type { GetSensorSearchResultV1 } from '@general-galactic/crystal-api-client'
import { debounce } from '@general-galactic/crystal-webapp-utils'
import { computed, ref, watch } from 'vue'

const loadingSearchResults = ref(false)
const searchTerm = ref<string | undefined>()
const searchResults = ref<GetSensorSearchResultV1[] | undefined>()

const emit = defineEmits<{
    (event: 'picked:sensor', sensor: GetSensorSearchResultV1): void
}>()

const canUseSearchTerm = computed(() => {
    if (!searchTerm.value) return false
    return searchTerm.value.length > 2
})

watch(
    searchTerm,
    debounce(() => {
        if (!canUseSearchTerm.value) {
            searchResults.value = undefined
            return
        }
        if (searchTerm.value) search(searchTerm.value)
    }, 300)
)

const search = async (searchTerm: string) => {
    if (!canUseSearchTerm.value) return
    try {
        loadingSearchResults.value = true
        const result = await useApi().getSensorSearchV1({ term: searchTerm, limit: 10 })
        searchResults.value = result.results
    } finally {
        loadingSearchResults.value = false
    }
}
</script>

<style lang="scss" scoped>
.sensorResult {
    cursor: pointer;
}
</style>
