<template>
    <v-tooltip :text="actionTriggerTooltipText(trigger)">
        <template v-slot:activator="{ props }">
            <v-chip :color="colorForActionTrigger(trigger)" v-bind="props" variant="flat" :prepend-icon="iconForActionTrigger(trigger)">
                {{ actionTriggerTitle(trigger) }}
            </v-chip>
        </template>
    </v-tooltip>
</template>

<script lang="ts" setup>
import { ActionTriggerTypesV1 } from '@general-galactic/crystal-api-client'
import type { PropType } from 'vue'

defineProps({
    trigger: {
        type: String as PropType<ActionTriggerTypesV1>,
        required: true
    }
})

const actionTriggerTitle = (triggerType: ActionTriggerTypesV1) => {
    switch (triggerType) {
        case ActionTriggerTypesV1.OnDemand:
            return 'On Demand'
        case ActionTriggerTypesV1.PromptChildAction:
            return 'Prompt Child'
        case ActionTriggerTypesV1.RefillEvent:
            return 'Refill'
        case ActionTriggerTypesV1.Static:
            return 'Static'
        case ActionTriggerTypesV1.System:
            return 'System'
        case ActionTriggerTypesV1.TimedActionIsDue:
            return 'Timed'
        case ActionTriggerTypesV1.User:
            return 'User'
        case ActionTriggerTypesV1.WaterReportNeeded:
            return 'Water Report'
        default:
            return `Unknown trigger type: ${triggerType}`
    }
}

const actionTriggerTooltipText = (triggerType: ActionTriggerTypesV1) => {
    switch (triggerType) {
        case ActionTriggerTypesV1.OnDemand:
            return 'Caused by monitor readings or water reports'
        case ActionTriggerTypesV1.PromptChildAction:
            return 'User answered a prompt that resulted in this action'
        case ActionTriggerTypesV1.RefillEvent:
            return 'Created when user refills'
        case ActionTriggerTypesV1.Static:
            return 'Created by events, stored in vessel status'
        case ActionTriggerTypesV1.System:
            return 'Disconnected actions, low battery actions, etc.'
        case ActionTriggerTypesV1.TimedActionIsDue:
            return 'Caused by the relentless passing of time'
        case ActionTriggerTypesV1.User:
            return 'User requested this action'
        case ActionTriggerTypesV1.WaterReportNeeded:
            return 'One or more required water report readings expired'
        default:
            return `Unknown trigger type: ${triggerType}`
    }
}

const iconForActionTrigger = (triggerType: ActionTriggerTypesV1) => {
    switch (triggerType) {
        case ActionTriggerTypesV1.OnDemand:
            return 'mdi-chart-line'
        case ActionTriggerTypesV1.PromptChildAction:
            return 'mdi-chat-question-outline'
        case ActionTriggerTypesV1.RefillEvent:
            return 'mdi-basket-fill'
        case ActionTriggerTypesV1.Static:
            return 'mdi-harddisk'
        case ActionTriggerTypesV1.System:
            return 'mdi-robot-dead'
        case ActionTriggerTypesV1.TimedActionIsDue:
            return 'mdi-clock-outline'
        case ActionTriggerTypesV1.User:
            return 'mdi-account'
        case ActionTriggerTypesV1.WaterReportNeeded:
            return 'mdi-led-strip'
        default:
            return `mdi-help`
    }
}

const colorForActionTrigger = (triggerType: ActionTriggerTypesV1) => {
    switch (triggerType) {
        case ActionTriggerTypesV1.OnDemand:
            return 'blue-lighten-1'
        case ActionTriggerTypesV1.TimedActionIsDue:
            return 'amber-darken-1'
        case ActionTriggerTypesV1.User:
            return 'blue-darken-2'
        default:
            return `purple-darken-1`
    }
}
</script>
