import { DefaultApi, createApiInstance } from '@general-galactic/crystal-api-client'
import { getAccessTokenSilentlyOutsideComponent } from '@/lib/auth'

let _api: DefaultApi | undefined = undefined

export const useApi = ({ rebuild = false } = {}): DefaultApi => {
    if (_api && !rebuild) return _api
    _api = createApiInstance({
        baseUrl: import.meta.env.VITE_CRYSTAL_API_BASE_URL,
        authToken: async (name?: string) => {
            if (name === 'auth0Bearer') {
                const token = await getAccessTokenSilentlyOutsideComponent()
                return token
            }
        },
        platform: 'web',
        appVersion: window.location.hostname,
        timeoutMs: 28000 // longer for admin so raw charts have to time to return
    })
    return _api
}
